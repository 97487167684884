import {Elm} from './elm/Chart.elm'
import {bindResizePorts} from "./elm_ports";

function initChart(context, flags) {
  const app = Elm.Chart.init({
    node: context,
    flags: flags
  });
  bindResizePorts(app);
}

export {initChart}