import {Elm} from './elm/RealTimeSeriesViewer.elm'
import {bindResizePorts} from "./elm_ports";
import WebSocketWrapper from "./javascript/websockets";

function initRealTimeSeriesViewer(context, flags) {
  const app = Elm.RealTimeSeriesViewer.init({
    node: context,
    flags: flags
  });

  bindResizePorts(app)

  app.ports.webSocketOut.subscribe(function (data) {
    if (data.msg === 'Open' && data.url && data.onOpenMessage) {
      new WebSocketWrapper(
        data.url,
        {
          reconnect: true,
          onOpenMessage: data.onOpenMessage,
          onMessage: function (payload) {
            app.ports.webSocketIn.send({
              msg: 'IncomingMessage',
              payload: payload.data
            })
          },
          onOpen: function (event) {
            app.ports.webSocketIn.send({
              msg: 'Opened'
            })
          },
          onClose: function (event) {
            app.ports.webSocketIn.send({
              msg: 'Closed'
            })
          }
        }
      )
    }
  });
}

export {initRealTimeSeriesViewer}