import {Elm} from './elm/MeterCorrectionForm.elm'
import {bindParsePort, bindReloadPort} from "./elm_ports";

function initMeterCorrectionForm(context, flags) {
  const app = Elm.MeterCorrectionForm.init({
    node: context,
    flags: flags
  });
  bindParsePort(app);
  bindReloadPort(app);
}

export {initMeterCorrectionForm}