import {Elm} from './elm/Tc/Form.elm'
import {
  bindDocumentEscapePressPort,
  bindNativeDialogPort,
  bindParsePort,
  bindReloadPort,
  bindResizePorts
} from './elm_ports'

function initTcForm(context, flags) {
  const app = Elm.Tc.Form.init({
    node: context,
    flags: flags
  });
  bindParsePort(app)
  bindDocumentEscapePressPort(app)
  bindReloadPort(app)
  bindResizePorts(app)
  bindNativeDialogPort(app)
}

export {initTcForm}