import {Elm} from "./elm/AreaForm.elm";
import {
  bindCloseDialogPort,
  bindParsePort,
  wrapElmContainer
} from "./elm_ports";


function initAreaForm(context, flags) {
  const wrapper = wrapElmContainer(context);
  const app = Elm.AreaForm.init({
    node: context,
    flags: flags
  });
  bindParsePort(app)
  bindCloseDialogPort(wrapper, app)
}

export {initAreaForm}