import {Controller} from "@hotwired/stimulus"
import {initTcForm} from "../../tc_form";
import {initAreaForm} from "../../area_form";
import {initPlans} from "../../plans";
import {initExportForm} from "../../export_form";
import {initVddForm} from "../../vdd_form";
import {initStreetLightOptimization} from "../../street_light_optimization";
import {
  initReadingConversionCalculator
} from "../../reading_conversion_calculator";
import {initElPriceConfiguration} from "../../el_price_configuration";
import {initMeterCorrectionForm} from "../../meter_correction_form";
import {initChartAndControlSet} from "../../chart_and_control_set";
import {initChart} from "../../chart";
import {
  initMetaUserImpersonationSelection
} from "../../meta_user_impersonation_selection";
import {initExportTemplateForm} from "../../export_template_form";
import {initNotificationForm} from "../../notification_form";
import {initRealTimeSeriesViewer} from "../../real-time-series-viewer";
import {initPriceListForm} from "../../price_list_form";
import {elmFlags} from "./mixins/elm_flags";

export default class extends Controller {
  static values = {
    app: String,
    flags: Object,
  }

  connect() {
    elmFlags(this);
    this.initAllElmApps(this.element, this.appValue, this.flags());
    // Elm takes over the element thus effectively detaching the controller
  }

  initAllElmApps(context, appName, flags) {
    switch (appName) {
      case 'tc-form':
        initTcForm(context, flags);
        break;
      case 'area-form':
        initAreaForm(context, flags);
        break;
      case 'plans':
        initPlans(context, flags);
        break;
      case 'export-form':
        initExportForm(context, flags);
        break;
      case 'vdd-form':
        initVddForm(context, flags);
        break;
      case 'street-light-optimization':
        initStreetLightOptimization(context, flags);
        break;
      case 'reading-conversion-calculator':
        initReadingConversionCalculator(context, flags);
        break;
      case 'el-price-configuration':
        initElPriceConfiguration(context, flags);
        break;
      case 'meter-correction-form':
        initMeterCorrectionForm(context, flags);
        break;
      case 'chart-and-control-set':
        initChartAndControlSet(context, flags);
        break;
      case 'chart':
        initChart(context, flags);
        break;
      case 'meta-user-impersonation-selection':
        initMetaUserImpersonationSelection(context, flags);
        break;
      case 'export-template-form':
        initExportTemplateForm(context, flags);
        break;
      case 'notification-form':
        initNotificationForm(context, flags);
        break;
      case 'real-time-series-viewer':
        initRealTimeSeriesViewer(context, flags);
        break;
      case 'price-list-form':
        initPriceListForm(context, flags);
        break;
      default:
        console.error(`Unknown Elm app name: ${appName}`);
    }
  }

  flags() {
    return this.augmentFlags(this.flagsValue);
  }
}