import {Elm} from './elm/Plans.elm'
import {bindResizePorts} from "./elm_ports";

function initPlans(context, flags) {
  const app = Elm.Plans.init({
    node: context,
    flags: flags
  });
  bindResizePorts(app)
}

export {initPlans}