import {parseLocalizedNumber} from "./util";

function bindRedirectToPort(app) {
  app.ports.redirectTo.subscribe(function (url) {
    window.location.href = url;
  });
}

function dateTimeToISODateTime(date) {
  return date.toISOString()
}

function normalizeDateTimeFormat(f) {
  return f.replace(/mm/, 'm').replace(/HH/, 'H')
}


function bindParsePort(app) {
  app.ports.parse.subscribe(function ({id, appName, type, value, ...msg}) {
      let parsed;
      switch (type) {
        case 'number':
          parsed = parseLocalizedNumber(value);
          if (typeof (parsed) === 'undefined')
            parsed = 0;

          app.ports.parseResult.send({id, appName, type, value: parsed});

          break;

        case 'dateTime':
          // the parser uses a different format from the formatter, so yay
          parsed = Date.parseExact(value, normalizeDateTimeFormat(msg.format));
          if (parsed === null)
            parsed = new Date();

          app.ports.parseResult.send({
            id,
            appName,
            type,
            value: dateTimeToISODateTime(parsed)
          });
          break;
      }
    }
  );
}

const bindDocumentEscapePressPort = function (app) {
  document.addEventListener('keyup', function (event) {
    if (event.defaultPrevented) {
      return;
    }

    const key = event.key || event.keyCode;

    if (key === 'Escape' || key === 'Esc' || key === 27) {
      app.ports.escapePressed.send(null);
    }
  });
}


function bindResizePorts(app) {
  function sendElementSizeToPort(id, $resizeEl) {
    const boundingBox = $resizeEl.getBoundingClientRect()
    app.ports.resized.send([
      id,
      {
        window: {
          width: Math.floor(window.innerWidth),
          height: Math.floor(window.innerHeight)
        },
        element: {
          width: Math.floor(boundingBox.width),
          height: Math.floor(boundingBox.height)
        }
      }
    ])
    ;
  }

  function runOnElementWithId(id, callback) {
    const $resizeEl = document.getElementById(id);
    if (!$resizeEl) {
      return;
    }

    callback($resizeEl);
  }

  if (app.ports.querySize) {
    app.ports.querySize.subscribe(function (id) {
      runOnElementWithId(id, function ($resizeEl) {
        sendElementSizeToPort(id, $resizeEl)
      });
    })
  }

  app.ports.monitorResize.subscribe(function (id) {
    const findElement = (callback, attempt = 0) => {
      const el = document.getElementById(id);
      if (el) {
        callback(el)
      } else if (attempt < 5) {
        setTimeout(function () {
          findElement(callback, attempt + 1);
        }, 50);
      }
    }
    findElement(el => {
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          sendElementSizeToPort(id, entry.target);
        }
      })
      resizeObserver.observe(el);
    });
  });
}

function bindReloadPort(app) {
  app.ports.reload.subscribe(function () {
    window.location.reload();
  });
}

function bindNativeDialogPort(app) {
  app.ports.toggleNativeDialog.subscribe(function ({id, show}) {
    const dialog = document.getElementById(id);

    if (show) {
      dialog.showModal();
    } else {
      dialog.close();
    }
  })
}

function bindCloseDialogPort(el, app) {
  app.ports.closeDialog.subscribe(function (payload) {
    el.closest('[data-dialog-target="dialog"]').dispatchEvent(new CustomEvent(`dialog:close`, payload))

    const controller = payload['controller'];
    const event = new CustomEvent(`${controller}:update`, payload);
    document.querySelectorAll(`[data-controller=${controller}]`).forEach(el => {
      el.dispatchEvent(event)
    })
  })
}

function wrapElmContainer(el) {
  const wrapper = document.createElement('div');
  el.parentNode.replaceChild(wrapper, el);
  wrapper.appendChild(el);
  return wrapper;
}

export {
  bindRedirectToPort,
  bindParsePort,
  bindDocumentEscapePressPort,
  bindCloseDialogPort,
  bindNativeDialogPort,
  bindReloadPort,
  bindResizePorts,
  wrapElmContainer
};