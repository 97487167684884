import {Elm} from './elm/PriceListForm.elm'
import {
  bindDocumentEscapePressPort,
  bindNativeDialogPort,
  bindParsePort,
  bindRedirectToPort
} from "./elm_ports";

function initPriceListForm(context, flags) {
  const app = Elm.PriceListForm.init({
    node: context,
    flags: flags
  });
  bindRedirectToPort(app)
  bindParsePort(app)
  bindDocumentEscapePressPort(app)
  bindNativeDialogPort(app)
}

export {initPriceListForm}