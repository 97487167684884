import {Elm} from './elm/ElPriceConfiguration.elm'
import {
  bindCloseDialogPort,
  bindParsePort,
  wrapElmContainer
} from "./elm_ports";

function initElPriceConfiguration(context, flags) {
  const wrapper = wrapElmContainer(context);
  const app = Elm.ElPriceConfiguration.init({
    node: context,
    flags: flags
  });
  bindParsePort(app)
  bindCloseDialogPort(wrapper, app)
}

export {initElPriceConfiguration}