import {Elm} from './elm/ExportForm.elm'
import {bindRedirectToPort} from "./elm_ports";

function initExportForm(context, flags) {
  const app = Elm.ExportForm.init({
    node: context,
    flags: flags
  });
  bindRedirectToPort(app)
}

export {initExportForm}