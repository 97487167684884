import {Elm} from './elm/StreetLightOptimization.elm'
import {
  bindCloseDialogPort,
  bindParsePort,
  wrapElmContainer
} from "./elm_ports";

function initStreetLightOptimization(context, flags) {
  const wrapper = wrapElmContainer(context);
  const app = Elm.StreetLightOptimization.init({
    node: context,
    flags: flags
  });
  bindParsePort(app)
  bindCloseDialogPort(wrapper, app)
}

export {initStreetLightOptimization}