import {Controller} from "@hotwired/stimulus";
import {useClickOutside} from "stimulus-use";

/**
 * Menu controller allows to open and close a drop down menu on a button click.
 * Drop down menu can be closed on clicking outside as well.
 *
 * The menu listens to `menu:show` event and opens itself when received, using
 * the first available opener.
 *
 * For situations when the menu has to react responsively, i.e. the opener is
 * not visible on large screen and the menu is simply displayed, the opener
 * appears only for small screens, the controller has to be marked by
 * `data-menu-responsive-toggler-value="true"`.
 *
 *  %nav{data: { controller: 'menu',
 *               menu_opened_class: 'flex',
 *               menu_closed_class: 'hidden' }}
 *    %button{aria: { expanded: 'false', label: 'Menu' },
 *            data: { action: 'click->menu#toggle'} }
 *
 *    %ul{data: { menu_target: 'content'}}
 *      %li Menu item 1
 *      %li Menu item 2
 *      %li Menu item 3
 */
export default class extends Controller {
  static targets = ['content'];
  static classes = ['opened', 'closed'];
  static values = {
    responsiveToggler: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    useClickOutside(this);

    if (this.responsiveTogglerValue) this._setUpResponsiveObserver()

    this.clickOutsideListener = this._clickOutsideListener.bind(this);

    this.element.querySelectorAll(`[data-action*="${this.context.module.identifier}#toggle"]`).forEach(el => {
      if (this.observer) this.observer.observe(el)

      // initialize into the declared state (not toggle it)
      if (el.ariaExpanded === 'true' || this.togglerIsHiddenByCss(el)) {
        this.showMenu(el);
      } else {
        this.hideMenu(el);
      }
    });

    this.showHandler = this._showHandler.bind(this)
    this.element.addEventListener('menu:show', this.showHandler)
  }

  togglerIsHiddenByCss(toggler) {
    return window.getComputedStyle(toggler)['display'] === 'none';
  }

  disconnect() {
    this._removeClickOutsideListener();
    super.disconnect();
    if (this.observer) this.observer.disconnect();
    this.element.removeEventListener('menu:show', this.showHandler);
  }

  toggle(event) {
    if (event.currentTarget.ariaExpanded === 'true') {
      this._removeClickOutsideListener();
      this.hideMenu(event.currentTarget);
    } else {
      this._addClickOutsideListener();
      this.showMenu(event.currentTarget);
    }
  }

  showMenu(toggler) {
    toggler.ariaExpanded = true;
    this.contentTarget.hidden = false;
    this.contentTarget.classList.add(this.openedClass);
    this.contentTarget.classList.remove(this.closedClass);

    if (toggler.dataset.menuCustomDropDownPlace === "true") {
      const togglerRect = toggler.getBoundingClientRect();
      const menu = this.contentTarget;
      menu.style.top = `${togglerRect.bottom + togglerRect.height}px`;
    }

    if (typeof this.contentTarget.show === 'function') {
      this.contentTarget.show();
    }
  }

  hideMenu(toggler) {
    if (this.hasContentTarget) {
      toggler.ariaExpanded = false;
      this.contentTarget.hidden = true;
      this.contentTarget.classList.remove(this.openedClass);
      this.contentTarget.classList.add(this.closedClass);

      if (typeof this.contentTarget.remove === 'function' && toggler.dataset['value'] === 'context') {
        this.contentTarget.remove();
      }
    }
  }

  _clickOutsideListener(event) {
    if (event.target.isConnected && !this.element.contains(event.target)) {
      this.element.querySelectorAll('[data-action]').forEach(el => {
        if (el.dataset['action'].includes(`->${this.context.module.identifier}#toggle`)) {
          this._removeClickOutsideListener();
          this.hideMenu(el);
        }
      });
    }
  }

  _setUpResponsiveObserver() {
    const controller = this;
    this.observer = new IntersectionObserver(
      function (entries) {
        const togglerIsVisible = entries[0].isIntersecting
        if (togglerIsVisible) {
          controller.hideMenu(entries[0].target);
        } else {
          if (controller.togglerIsHiddenByCss(entries[0].target)) {
            controller.showMenu(entries[0].target);
          }
        }
      },
      {root: null}
    );
  }

  _addClickOutsideListener() {
    document.addEventListener('click', this.clickOutsideListener);
  }

  _removeClickOutsideListener() {
    document.removeEventListener('click', this.clickOutsideListener);
  }

  _showHandler() {
    const toggler = this.element.querySelector(`[data-action*="${this.context.module.identifier}#toggle"]`);
    this._addClickOutsideListener();
    this.showMenu(toggler);
  }
}