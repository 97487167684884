class WebSocketWrapper {
  constructor(url, opts = {}) {
    this.url = url;
    this.opts = opts;

    this.resetBackoff();
    this.connect();
  }

  connect() {
    const self = this;
    self.socket = new WebSocket(self.url);
    self.resetBackoff();

    self.socket.addEventListener("open", function (event) {
      if (self.opts.onOpenMessage && self.opts.onOpenMessage !== "") {
        self.socket.send(self.opts.onOpenMessage)
      }

      if (self.opts.onOpen) {
        self.opts.onOpen(event);
      }
    });

    self.socket.addEventListener("message", function (event) {
      if (!self.opts.onMessage) {
        return;
      }

      self.opts.onMessage(event);
    });

    self.socket.addEventListener("close", function (event) {
      if (self.opts.onClose) {
        self.opts.onClose(event);
      }

      self.reconnect();
    });
  }

  reconnect() {
    const self = this;
    if (self.opts.reconnect) {
      self.increaseBackoff();
      setTimeout(() => {
        self.connect()
      }, self.backoff * 1000);
    }
  }

  resetBackoff() {
    this.backoff = 1;
  }

  increaseBackoff() {
    this.backoff = this.backoff * 2;
  }

}

export default WebSocketWrapper;