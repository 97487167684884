export const elmFlags = controller => {
  Object.assign(controller, {
    augmentFlags(flags) {
      flags.locale = window.I18n.locale;
      flags.translations = window.I18n.translations[window.I18n.locale];
      flags.csrfToken = this.csrfTokenFromMetaTag();
      flags.url = window.location.href;

      return flags;
    },

    csrfTokenFromMetaTag() {
      const metaTags = document.getElementsByName('csrf-token');
      if (!metaTags || metaTags.length === 0) {
        return 'no-csrf-token';
      }

      return metaTags[0].content;
    }
  })
}