import {Elm} from './elm/ExportTemplate.elm'
import {bindRedirectToPort, bindResizePorts} from "./elm_ports";

function initExportTemplateForm(context, flags) {
  const app = Elm.ExportTemplate.init({
    node: context,
    flags: flags
  });
  bindRedirectToPort(app)
  bindResizePorts(app)
}

export {initExportTemplateForm}