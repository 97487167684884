// -- (function(scope){
// -- 'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.iR.cy === region.fx.cy)
	{
		return 'on line ' + region.iR.cy;
	}
	return 'on lines ' + region.iR.cy + ' through ' + region.fx.cy;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.h3,
		impl.i8,
		impl.iV,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


/*
function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}

*/

/*
function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}
*/


/*
function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}

*/

/*
function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}
*/




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS
//
// For some reason, tabs can appear in href protocols and it still works.
// So '\tjava\tSCRIPT:alert("!!!")' and 'javascript:alert("!!!")' are the same
// in practice. That is why _VirtualDom_RE_js and _VirtualDom_RE_js_html look
// so freaky.
//
// Pulling the regular expressions out to the top level gives a slight speed
// boost in small benchmarks (4-10%) but hoisting values to reduce allocation
// can be unpredictable in large programs where JIT may have a harder time with
// functions are not fully self-contained. The benefit is more that the js and
// js_html ones are so weird that I prefer to see them near each other.


var _VirtualDom_RE_script = /^script$/i;
var _VirtualDom_RE_on_formAction = /^(on|formAction$)/i;
var _VirtualDom_RE_js = /^\s*j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:/i;
var _VirtualDom_RE_js_html = /^\s*(j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:|d\s*a\s*t\s*a\s*:\s*t\s*e\s*x\s*t\s*\/\s*h\s*t\s*m\s*l\s*(,|;))/i;


function _VirtualDom_noScript(tag)
{
	return _VirtualDom_RE_script.test(tag) ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return _VirtualDom_RE_on_formAction.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return _VirtualDom_RE_js.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return _VirtualDom_RE_js_html.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlJson(value)
{
	return (typeof _Json_unwrap(value) === 'string' && _VirtualDom_RE_js_html.test(_Json_unwrap(value)))
		? _Json_wrap(
			/**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		) : value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		a3: func(record.a3),
		eY: record.eY,
		eI: record.eI
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.a3;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.eY;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.eI) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.h3,
		impl.i8,
		impl.iV,
		function(sendToApp, initialModel) {
			var view = impl.jb;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.h3,
		impl.i8,
		impl.iV,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.eR && impl.eR(sendToApp)
			var view = impl.jb;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.hq);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.i$) && (_VirtualDom_doc.title = title = doc.i$);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.iu;
	var onUrlRequest = impl.iv;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		eR: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.gv === next.gv
							&& curr.fI === next.fI
							&& curr.gq.a === next.gq.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		h3: function(flags)
		{
			return A3(impl.h3, flags, _Browser_getUrl(), key);
		},
		jb: impl.jb,
		i8: impl.i8,
		iV: impl.iV
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { h$: 'hidden', hx: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { h$: 'mozHidden', hx: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { h$: 'msHidden', hx: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { h$: 'webkitHidden', hx: 'webkitvisibilitychange' }
		: { h$: 'hidden', hx: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		gJ: _Browser_getScene(),
		g5: {
			hb: _Browser_window.pageXOffset,
			hc: _Browser_window.pageYOffset,
			e8: _Browser_doc.documentElement.clientWidth,
			dU: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		e8: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		dU: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			gJ: {
				e8: node.scrollWidth,
				dU: node.scrollHeight
			},
			g5: {
				hb: node.scrollLeft,
				hc: node.scrollTop,
				e8: node.clientWidth,
				dU: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			gJ: _Browser_getScene(),
			g5: {
				hb: x,
				hc: y,
				e8: _Browser_doc.documentElement.clientWidth,
				dU: _Browser_doc.documentElement.clientHeight
			},
			fw: {
				hb: x + rect.left,
				hc: y + rect.top,
				e8: rect.width,
				dU: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.ih) { flags += 'm'; }
	if (options.hv) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.hS.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.hS.b, xhr)); });
		$elm$core$Maybe$isJust(request.i6) && _Http_track(router, xhr, request.i6.a);

		try {
			xhr.open(request.id, request.i9, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.i9));
		}

		_Http_configureRequest(xhr, request);

		request.hq.a && xhr.setRequestHeader('Content-Type', request.hq.a);
		xhr.send(request.hq.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.hZ; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.i_.a || 0;
	xhr.responseType = request.hS.d;
	xhr.withCredentials = request.hi;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		i9: xhr.responseURL,
		gR: xhr.status,
		iS: xhr.statusText,
		hZ: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			iO: event.loaded,
			gN: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			iB: event.loaded,
			gN: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}var $author$project$ElPriceConfiguration$ValidModel = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.y) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.C),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.C);
		} else {
			var treeLen = builder.y * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.G) : builder.G;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.y);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.C) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.C);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{G: nodeList, y: (len / $elm$core$Array$branchFactor) | 0, C: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {fE: fragment, fI: host, gn: path, gq: port_, gv: protocol, gw: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $author$project$ElPriceConfiguration$ErrorModel = function (a) {
	return {$: 1, a: a};
};
var $author$project$AreaForm$Inner$NotAsked = {$: 0};
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$ElPriceConfiguration$Flags = F9(
	function (locale, translations, csrfToken, today, distributionRates, phaseCounts, intervals, updateUrl, seed) {
		return {ck: csrfToken, bK: distributionRates, f: intervals, f$: locale, b$: phaseCounts, eQ: seed, an: today, au: translations, cX: updateUrl};
	});
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$core$Basics$neq = _Utils_notEqual;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.iR, posixMinutes) < 0) {
					return posixMinutes + era.it;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		fq: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		f4: month,
		hd: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).fq;
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).f4;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).hd;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {fm: col, hD: contextStack, gs: problem, gF: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.gF, s.fm, x, s.i));
	});
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.it, s.d);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{fm: 1, i: s.i, m: s.m, it: s.it + 1, gF: s.gF + 1, d: s.d}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{fm: s.fm + 1, i: s.i, m: s.m, it: newOffset, gF: s.gF, d: s.d}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $justinmimbs$date$Date$deadEndToString = function (_v0) {
	var problem = _v0.gs;
	if (problem.$ === 12) {
		var message = problem.a;
		return message;
	} else {
		return 'Expected a date in ISO 8601 format';
	}
};
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.d),
			s.it) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $justinmimbs$date$Date$MonthAndDay = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $justinmimbs$date$Date$OrdinalDay = function (a) {
	return {$: 2, a: a};
};
var $justinmimbs$date$Date$WeekAndWeekday = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$Advanced$commit = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, true, a, s);
	};
};
var $elm$parser$Parser$commit = $elm$parser$Parser$Advanced$commit;
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.it, s1.it, s0.d),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$mapChompedString = $elm$parser$Parser$Advanced$mapChompedString;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $justinmimbs$date$Date$int1 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	$elm$parser$Parser$chompIf($elm$core$Char$isDigit));
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $justinmimbs$date$Date$int2 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed(0),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $justinmimbs$date$Date$int3 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.it, s.gF, s.fm, s.d);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{fm: newCol, i: s.i, m: s.m, it: newOffset, gF: newRow, d: s.d});
	};
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $justinmimbs$date$Date$dayOfYear = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed($elm$core$Basics$identity),
				$elm$parser$Parser$token('-')),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$backtrackable(
						A2(
							$elm$parser$Parser$andThen,
							$elm$parser$Parser$commit,
							A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int2),
									$elm$parser$Parser$succeed(1)
								]))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$ignorer,
								$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
								$elm$parser$Parser$token('W')),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int1),
									$elm$parser$Parser$succeed(1)
								])))
					]))),
			$elm$parser$Parser$backtrackable(
			A2(
				$elm$parser$Parser$andThen,
				$elm$parser$Parser$commit,
				A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
						$justinmimbs$date$Date$int2),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$justinmimbs$date$Date$int2,
								$elm$parser$Parser$succeed(1)
							]))))),
			A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3),
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
					$elm$parser$Parser$token('W')),
				$justinmimbs$date$Date$int2),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$justinmimbs$date$Date$int1,
						$elm$parser$Parser$succeed(1)
					]))),
			$elm$parser$Parser$succeed(
			$justinmimbs$date$Date$OrdinalDay(1))
		]));
var $justinmimbs$date$Date$isBetweenInt = F3(
	function (a, b, x) {
		return (_Utils_cmp(a, x) < 1) && (_Utils_cmp(x, b) < 1);
	});
var $justinmimbs$date$Date$monthToName = function (m) {
	switch (m) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$fromCalendarParts = F3(
	function (y, mn, d) {
		return (!A3($justinmimbs$date$Date$isBetweenInt, 1, 12, mn)) ? $elm$core$Result$Err(
			'Invalid date: ' + (('month ' + ($elm$core$String$fromInt(mn) + ' is out of range')) + (' (1 to 12)' + ('; received (year ' + ($elm$core$String$fromInt(y) + (', month ' + ($elm$core$String$fromInt(mn) + (', day ' + ($elm$core$String$fromInt(d) + ')'))))))))) : ((!A3(
			$justinmimbs$date$Date$isBetweenInt,
			1,
			A2(
				$justinmimbs$date$Date$daysInMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn)),
			d)) ? $elm$core$Result$Err(
			'Invalid date: ' + (('day ' + ($elm$core$String$fromInt(d) + ' is out of range')) + ((' (1 to ' + ($elm$core$String$fromInt(
				A2(
					$justinmimbs$date$Date$daysInMonth,
					y,
					$justinmimbs$date$Date$numberToMonth(mn))) + ')')) + ((' for ' + $justinmimbs$date$Date$monthToName(
				$justinmimbs$date$Date$numberToMonth(mn))) + ((((mn === 2) && (d === 29)) ? (' (' + ($elm$core$String$fromInt(y) + ' is not a leap year)')) : '') + ('; received (year ' + ($elm$core$String$fromInt(y) + (', month ' + ($elm$core$String$fromInt(mn) + (', day ' + ($elm$core$String$fromInt(d) + ')'))))))))))) : $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeYear(y) + A2(
				$justinmimbs$date$Date$daysBeforeMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn))) + d));
	});
var $justinmimbs$date$Date$fromOrdinalParts = F2(
	function (y, od) {
		var daysInYear = $justinmimbs$date$Date$isLeapYear(y) ? 366 : 365;
		return (!A3($justinmimbs$date$Date$isBetweenInt, 1, daysInYear, od)) ? $elm$core$Result$Err(
			'Invalid ordinal date: ' + (('ordinal-day ' + ($elm$core$String$fromInt(od) + ' is out of range')) + ((' (1 to ' + ($elm$core$String$fromInt(daysInYear) + ')')) + ((' for ' + $elm$core$String$fromInt(y)) + ('; received (year ' + ($elm$core$String$fromInt(y) + (', ordinal-day ' + ($elm$core$String$fromInt(od) + ')')))))))) : $elm$core$Result$Ok(
			$justinmimbs$date$Date$daysBeforeYear(y) + od);
	});
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(jan4);
};
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$daysBeforeYear(y) + 1;
};
var $justinmimbs$date$Date$is53WeekYear = function (y) {
	var wdnJan1 = $justinmimbs$date$Date$weekdayNumber(
		$justinmimbs$date$Date$firstOfYear(y));
	return (wdnJan1 === 4) || ((wdnJan1 === 3) && $justinmimbs$date$Date$isLeapYear(y));
};
var $justinmimbs$date$Date$fromWeekParts = F3(
	function (wy, wn, wdn) {
		var weeksInYear = $justinmimbs$date$Date$is53WeekYear(wy) ? 53 : 52;
		return (!A3($justinmimbs$date$Date$isBetweenInt, 1, weeksInYear, wn)) ? $elm$core$Result$Err(
			'Invalid week date: ' + (('week ' + ($elm$core$String$fromInt(wn) + ' is out of range')) + ((' (1 to ' + ($elm$core$String$fromInt(weeksInYear) + ')')) + ((' for ' + $elm$core$String$fromInt(wy)) + ('; received (year ' + ($elm$core$String$fromInt(wy) + (', week ' + ($elm$core$String$fromInt(wn) + (', weekday ' + ($elm$core$String$fromInt(wdn) + ')')))))))))) : ((!A3($justinmimbs$date$Date$isBetweenInt, 1, 7, wdn)) ? $elm$core$Result$Err(
			'Invalid week date: ' + (('weekday ' + ($elm$core$String$fromInt(wdn) + ' is out of range')) + (' (1 to 7)' + ('; received (year ' + ($elm$core$String$fromInt(wy) + (', week ' + ($elm$core$String$fromInt(wn) + (', weekday ' + ($elm$core$String$fromInt(wdn) + ')'))))))))) : $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeWeekYear(wy) + ((wn - 1) * 7)) + wdn));
	});
var $justinmimbs$date$Date$fromYearAndDayOfYear = function (_v0) {
	var y = _v0.a;
	var doy = _v0.b;
	switch (doy.$) {
		case 0:
			var mn = doy.a;
			var d = doy.b;
			return A3($justinmimbs$date$Date$fromCalendarParts, y, mn, d);
		case 1:
			var wn = doy.a;
			var wdn = doy.b;
			return A3($justinmimbs$date$Date$fromWeekParts, y, wn, wdn);
		default:
			var od = doy.a;
			return A2($justinmimbs$date$Date$fromOrdinalParts, y, od);
	}
};
var $justinmimbs$date$Date$int4 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed(0),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									$elm$parser$Parser$chompIf(
									function (c) {
										return c === '-';
									}),
									$elm$parser$Parser$succeed(0)
								]))),
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $justinmimbs$date$Date$resultToParser = function (result) {
	if (!result.$) {
		var x = result.a;
		return $elm$parser$Parser$succeed(x);
	} else {
		var message = result.a;
		return $elm$parser$Parser$problem(message);
	}
};
var $justinmimbs$date$Date$parser = A2(
	$elm$parser$Parser$andThen,
	A2($elm$core$Basics$composeR, $justinmimbs$date$Date$fromYearAndDayOfYear, $justinmimbs$date$Date$resultToParser),
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Tuple$pair),
			$justinmimbs$date$Date$int4),
		$justinmimbs$date$Date$dayOfYear));
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {fm: col, gs: problem, gF: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.gF, p.fm, p.gs);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{fm: 1, i: _List_Nil, m: 1, it: 0, gF: 1, d: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $justinmimbs$date$Date$fromIsoString = A2(
	$elm$core$Basics$composeR,
	$elm$parser$Parser$run(
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			A2(
				$elm$parser$Parser$ignorer,
				$justinmimbs$date$Date$parser,
				A2(
					$elm$parser$Parser$andThen,
					$justinmimbs$date$Date$resultToParser,
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2($elm$parser$Parser$map, $elm$core$Result$Ok, $elm$parser$Parser$end),
								A2(
								$elm$parser$Parser$map,
								$elm$core$Basics$always(
									$elm$core$Result$Err('Expected a date only, not a date and time')),
								$elm$parser$Parser$chompIf(
									$elm$core$Basics$eq('T'))),
								$elm$parser$Parser$succeed(
								$elm$core$Result$Err('Expected a date only'))
							])))))),
	$elm$core$Result$mapError(
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$head,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map($justinmimbs$date$Date$deadEndToString),
				$elm$core$Maybe$withDefault('')))));
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {h2: index, ia: match, dk: number, gU: submatches};
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{hv: false, ih: false},
		string);
};
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$Enectiva$Conversion$parseStdDateWithFallback = F2(
	function (fallback, str) {
		return A2(
			$elm$core$Result$withDefault,
			fallback,
			$justinmimbs$date$Date$fromIsoString(
				function () {
					var _v0 = $elm$regex$Regex$fromString('T.+$');
					if (_v0.$ === 1) {
						return str;
					} else {
						var regex = _v0.a;
						return A3(
							$elm$regex$Regex$replace,
							regex,
							$elm$core$Basics$always(''),
							str);
					}
				}()));
	});
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Enectiva$Conversion$parseStdDate = $author$project$Enectiva$Conversion$parseStdDateWithFallback(
	A2(
		$justinmimbs$date$Date$fromPosix,
		$elm$time$Time$utc,
		$elm$time$Time$millisToPosix(0)));
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Enectiva$Conversion$dateDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		return $elm$json$Json$Decode$succeed(
			$author$project$Enectiva$Conversion$parseStdDate(s));
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$fail = _Json_fail;
var $turboMaCk$non_empty_list_alias$List$NonEmpty$fromList = function (xs) {
	if (xs.b) {
		var h = xs.a;
		var t = xs.b;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(h, t));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $turboMaCk$non_empty_list_alias$List$NonEmpty$decodeListHelper = function (xs) {
	var _v0 = $turboMaCk$non_empty_list_alias$List$NonEmpty$fromList(xs);
	if (!_v0.$) {
		var res = _v0.a;
		return $elm$json$Json$Decode$succeed(res);
	} else {
		return $elm$json$Json$Decode$fail('Expecting at least ONE ELEMENT array');
	}
};
var $elm$json$Json$Decode$list = _Json_decodeList;
var $turboMaCk$non_empty_list_alias$List$NonEmpty$decodeList = function (decoder) {
	return A2(
		$elm$json$Json$Decode$andThen,
		$turboMaCk$non_empty_list_alias$List$NonEmpty$decodeListHelper,
		$elm$json$Json$Decode$list(decoder));
};
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $author$project$ElPriceConfiguration$Interval = F7(
	function (id, range, mainBreakerRatedCurrent, numberOfMainBreakerPhases, distributionRate, reservedCapacity, reservedWattage) {
		return {bJ: distributionRate, dX: id, bt: mainBreakerRatedCurrent, bY: numberOfMainBreakerPhases, gy: range, bx: reservedCapacity, by: reservedWattage};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_uuid$Prng$Uuid$Uuid = $elm$core$Basics$identity;
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$never = _Regex_never;
var $NoRedInk$elm_uuid$Prng$Uuid$Barebones$uuidRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^[0-9A-Fa-f]{8,8}-[0-9A-Fa-f]{4,4}-[1-5][0-9A-Fa-f]{3,3}-[8-9A-Ba-b][0-9A-Fa-f]{3,3}-[0-9A-Fa-f]{12,12}$'));
var $NoRedInk$elm_uuid$Prng$Uuid$Barebones$isValidUuid = function (uuidAsString) {
	return A2($elm$regex$Regex$contains, $NoRedInk$elm_uuid$Prng$Uuid$Barebones$uuidRegex, uuidAsString);
};
var $NoRedInk$elm_uuid$Prng$Uuid$isValidUuid = $NoRedInk$elm_uuid$Prng$Uuid$Barebones$isValidUuid;
var $elm$core$String$toLower = _String_toLower;
var $NoRedInk$elm_uuid$Prng$Uuid$fromString = function (text) {
	return $NoRedInk$elm_uuid$Prng$Uuid$isValidUuid(text) ? $elm$core$Maybe$Just(
		$elm$core$String$toLower(text)) : $elm$core$Maybe$Nothing;
};
var $NoRedInk$elm_uuid$Prng$Uuid$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		var _v0 = $NoRedInk$elm_uuid$Prng$Uuid$fromString(string);
		if (!_v0.$) {
			var uuid = _v0.a;
			return $elm$json$Json$Decode$succeed(uuid);
		} else {
			return $elm$json$Json$Decode$fail('Not a valid UUID');
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $elm$json$Json$Decode$field = _Json_decodeField;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Enectiva$DateRange$SinceForever = 0;
var $author$project$Enectiva$DateRange$UntilForever = 0;
var $author$project$Enectiva$DateRange$BoundedDate = function (a) {
	return {$: 1, a: a};
};
var $author$project$Enectiva$DateRange$Infinity = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $author$project$Enectiva$DateRange$rangeBoundaryDecoder = function (infinity) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $author$project$Enectiva$DateRange$BoundedDate, $author$project$Enectiva$Conversion$dateDecoder),
				$elm$json$Json$Decode$null(
				$author$project$Enectiva$DateRange$Infinity(infinity))
			]));
};
var $author$project$Enectiva$DateRange$unboundedRangeDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'valid_to',
	$author$project$Enectiva$DateRange$rangeBoundaryDecoder(0),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'valid_from',
		$author$project$Enectiva$DateRange$rangeBoundaryDecoder(0),
		$elm$json$Json$Decode$succeed($elm$core$Tuple$pair)));
var $author$project$ElPriceConfiguration$intervalDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'reserved_wattage',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$float),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'reserved_capacity',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$float),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'distribution_rate',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'number_of_main_breaker_phases',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'main_breaker_rated_current',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$float),
					A2(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
						$author$project$Enectiva$DateRange$unboundedRangeDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$NoRedInk$elm_uuid$Prng$Uuid$decoder,
							$elm$json$Json$Decode$succeed($author$project$ElPriceConfiguration$Interval))))))));
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$ElPriceConfiguration$flagsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'seed',
	$turboMaCk$non_empty_list_alias$List$NonEmpty$decodeList($elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'updateUrl',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'intervals',
			$turboMaCk$non_empty_list_alias$List$NonEmpty$decodeList($author$project$ElPriceConfiguration$intervalDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'phaseCounts',
				$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'distributionRates',
					$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'today',
						$author$project$Enectiva$Conversion$dateDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'csrfToken',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'translations',
								$elm$json$Json$Decode$value,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'locale',
									$elm$json$Json$Decode$string,
									$elm$json$Json$Decode$succeed($author$project$ElPriceConfiguration$Flags))))))))));
var $turboMaCk$non_empty_list_alias$List$NonEmpty$head = function (_v0) {
	var h = _v0.a;
	return h;
};
var $NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$Seed = $elm$core$Basics$identity;
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{G: nodeList, y: nodeListSize, C: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $NoRedInk$elm_random_pcg_extended$Internal$Pcg$Seed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $NoRedInk$elm_random_pcg_extended$Internal$Pcg$next = function (_v0) {
	var state0 = _v0.a;
	var incr = _v0.b;
	return A2($NoRedInk$elm_random_pcg_extended$Internal$Pcg$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var $NoRedInk$elm_random_pcg_extended$Internal$Pcg$initialSeed = function (x) {
	var _v0 = $NoRedInk$elm_random_pcg_extended$Internal$Pcg$next(
		A2($NoRedInk$elm_random_pcg_extended$Internal$Pcg$Seed, 0, 1013904223));
	var state1 = _v0.a;
	var incr = _v0.b;
	var state2 = (state1 + x) >>> 0;
	return $NoRedInk$elm_random_pcg_extended$Internal$Pcg$next(
		A2($NoRedInk$elm_random_pcg_extended$Internal$Pcg$Seed, state2, incr));
};
var $NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$initialSeed = F2(
	function (baseSeed, extendedSeed) {
		return {
			a_: $NoRedInk$elm_random_pcg_extended$Internal$Pcg$initialSeed(baseSeed),
			ay: $elm$core$Array$fromList(extendedSeed)
		};
	});
var $author$project$Enectiva$I18n$Translations = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Enectiva$I18n$newTranslations = $author$project$Enectiva$I18n$Translations;
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Enectiva$Util$noCmd = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $turboMaCk$non_empty_list_alias$List$NonEmpty$tail = function (_v0) {
	var t = _v0.b;
	return t;
};
var $author$project$ElPriceConfiguration$init = function (json) {
	return $author$project$Enectiva$Util$noCmd(
		function () {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$ElPriceConfiguration$flagsDecoder, json);
			if (!_v0.$) {
				var flags = _v0.a;
				return $author$project$ElPriceConfiguration$ValidModel(
					{
						ck: flags.ck,
						bK: flags.bK,
						f: flags.f,
						al: $elm$core$Maybe$Nothing,
						b$: flags.b$,
						bz: $author$project$AreaForm$Inner$NotAsked,
						an: flags.an,
						au: A2($author$project$Enectiva$I18n$newTranslations, flags.f$, flags.au),
						cX: flags.cX,
						cb: A2(
							$NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$initialSeed,
							$turboMaCk$non_empty_list_alias$List$NonEmpty$head(flags.eQ),
							$turboMaCk$non_empty_list_alias$List$NonEmpty$tail(flags.eQ))
					});
			} else {
				var err = _v0.a;
				return $author$project$ElPriceConfiguration$ErrorModel(
					$elm$json$Json$Decode$errorToString(err));
			}
		}());
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$ElPriceConfiguration$IrrelevantPortMessaged = {$: 5};
var $author$project$ElPriceConfiguration$appName = 'epc';
var $author$project$Enectiva$I18n$matchNumber = F2(
	function (m, ms) {
		return _Utils_update(
			ms,
			{dk: m});
	});
var $author$project$Enectiva$I18n$emptyParsingMatcher = F2(
	function (_v0, _v1) {
		return $elm$core$Maybe$Nothing;
	});
var $author$project$Enectiva$I18n$matchers = {c2: $author$project$Enectiva$I18n$emptyParsingMatcher, c3: $author$project$Enectiva$I18n$emptyParsingMatcher, c4: $author$project$Enectiva$I18n$emptyParsingMatcher, dk: $author$project$Enectiva$I18n$emptyParsingMatcher};
var $author$project$Enectiva$I18n$ParsedDate = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Enectiva$I18n$ParsedDateRange = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Enectiva$I18n$ParsedDateTime = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Enectiva$I18n$ParsedNumber = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Enectiva$I18n$ParsingError = function (a) {
	return {$: 4, a: a};
};
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $rtfeldman$elm_iso8601_date_strings$DeadEnds$problemToString = function (p) {
	switch (p.$) {
		case 0:
			var s = p.a;
			return 'expecting \'' + (s + '\'');
		case 1:
			return 'expecting int';
		case 2:
			return 'expecting hex';
		case 3:
			return 'expecting octal';
		case 4:
			return 'expecting binary';
		case 5:
			return 'expecting float';
		case 6:
			return 'expecting number';
		case 7:
			return 'expecting variable';
		case 8:
			var s = p.a;
			return 'expecting symbol \'' + (s + '\'');
		case 9:
			var s = p.a;
			return 'expecting keyword \'' + (s + '\'');
		case 10:
			return 'expecting end';
		case 11:
			return 'unexpected char';
		case 12:
			var s = p.a;
			return 'problem ' + s;
		default:
			return 'bad repeat';
	}
};
var $rtfeldman$elm_iso8601_date_strings$DeadEnds$deadEndToString = function (deadend) {
	return $rtfeldman$elm_iso8601_date_strings$DeadEnds$problemToString(deadend.gs) + (' at row ' + ($elm$core$String$fromInt(deadend.gF) + (', col ' + $elm$core$String$fromInt(deadend.fm))));
};
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $rtfeldman$elm_iso8601_date_strings$DeadEnds$deadEndsToString = function (deadEnds) {
	return $elm$core$String$concat(
		A2(
			$elm$core$List$intersperse,
			'; ',
			A2($elm$core$List$map, $rtfeldman$elm_iso8601_date_strings$DeadEnds$deadEndToString, deadEnds)));
};
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.d);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.it, offset) < 0,
					0,
					{fm: col, i: s0.i, m: s0.m, it: offset, gF: row, d: s0.d});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.it, s.gF, s.fm, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$core$Basics$round = _Basics_round;
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$append = _String_append;
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
										$elm$parser$Parser$succeed(0)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(str);
		if (_v0.$ === 1) {
			var deadEnds = _v0.a;
			return $elm$json$Json$Decode$fail(
				$rtfeldman$elm_iso8601_date_strings$DeadEnds$deadEndsToString(deadEnds));
		} else {
			var time = _v0.a;
			return $elm$json$Json$Decode$succeed(time);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Enectiva$DateRange$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'to',
	$author$project$Enectiva$Conversion$dateDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'from',
		$author$project$Enectiva$Conversion$dateDecoder,
		$elm$json$Json$Decode$succeed($elm$core$Tuple$pair)));
var $author$project$Enectiva$I18n$resultDateRangeDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$andThen,
			function (err) {
				return $elm$json$Json$Decode$succeed(
					$elm$core$Result$Err(err));
			},
			A2($elm$json$Json$Decode$field, 'err', $elm$json$Json$Decode$string)),
			A2(
			$elm$json$Json$Decode$andThen,
			function (range) {
				return $elm$json$Json$Decode$succeed(
					$elm$core$Result$Ok(range));
			},
			$author$project$Enectiva$DateRange$decoder)
		]));
var $author$project$Enectiva$I18n$parseDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (tp) {
		switch (tp) {
			case 'number':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'value',
					$elm$json$Json$Decode$float,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$value,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'appName',
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed(
								F3(
									function (appName, id, value) {
										return A2(
											$author$project$Enectiva$I18n$ParsedNumber,
											_Utils_Tuple2(appName, id),
											value);
									})))));
			case 'date':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'value',
					$author$project$Enectiva$Conversion$dateDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$value,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'appName',
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed(
								F3(
									function (appName, id, value) {
										return A2(
											$author$project$Enectiva$I18n$ParsedDate,
											_Utils_Tuple2(appName, id),
											value);
									})))));
			case 'dateTime':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'value',
					$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$value,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'appName',
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed(
								F3(
									function (appName, id, value) {
										return A2(
											$author$project$Enectiva$I18n$ParsedDateTime,
											_Utils_Tuple2(appName, id),
											value);
									})))));
			case 'dateRange':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'value',
					$author$project$Enectiva$I18n$resultDateRangeDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$value,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'appName',
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed(
								F3(
									function (appName, id, value) {
										return A2(
											$author$project$Enectiva$I18n$ParsedDateRange,
											_Utils_Tuple2(appName, id),
											value);
									})))));
			default:
				var anotherType = tp;
				return $elm$json$Json$Decode$succeed(
					$author$project$Enectiva$I18n$ParsingError('unrecognized type ' + anotherType));
		}
	},
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
var $author$project$Enectiva$I18n$parseResult = _Platform_incomingPort('parseResult', $elm$json$Json$Decode$value);
var $author$project$Enectiva$I18n$parsed = F3(
	function (noop, appName, _v0) {
		var number = _v0.dk;
		var date = _v0.c2;
		var dateTime = _v0.c4;
		var dateRange = _v0.c3;
		return $author$project$Enectiva$I18n$parseResult(
			function (pr) {
				var _v1 = A2($elm$json$Json$Decode$decodeValue, $author$project$Enectiva$I18n$parseDecoder, pr);
				if (!_v1.$) {
					switch (_v1.a.$) {
						case 0:
							var _v2 = _v1.a;
							var _v3 = _v2.a;
							var an = _v3.a;
							var id = _v3.b;
							var n = _v2.b;
							return _Utils_eq(appName, an) ? A2(
								$elm$core$Maybe$withDefault,
								noop,
								A2(number, id, n)) : noop;
						case 1:
							var _v4 = _v1.a;
							var _v5 = _v4.a;
							var an = _v5.a;
							var id = _v5.b;
							var d = _v4.b;
							return _Utils_eq(appName, an) ? A2(
								$elm$core$Maybe$withDefault,
								noop,
								A2(date, id, d)) : noop;
						case 2:
							var _v6 = _v1.a;
							var _v7 = _v6.a;
							var an = _v7.a;
							var id = _v7.b;
							var dt = _v6.b;
							return _Utils_eq(appName, an) ? A2(
								$elm$core$Maybe$withDefault,
								noop,
								A2(dateTime, id, dt)) : noop;
						case 3:
							var _v8 = _v1.a;
							var _v9 = _v8.a;
							var an = _v9.a;
							var id = _v9.b;
							var resultRange = _v8.b;
							return _Utils_eq(appName, an) ? A2(
								$elm$core$Maybe$withDefault,
								noop,
								A2(dateRange, id, resultRange)) : noop;
						default:
							return noop;
					}
				} else {
					return noop;
				}
			});
	});
var $author$project$ElPriceConfiguration$FloatParsed = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $author$project$Enectiva$Conversion$decodeResult = function (result) {
	if (!result.$) {
		var a = result.a;
		return $elm$json$Json$Decode$succeed(a);
	} else {
		var err = result.a;
		return $elm$json$Json$Decode$fail(err);
	}
};
var $author$project$ElPriceConfiguration$MainBreakerRatedCurrent = 0;
var $author$project$ElPriceConfiguration$ReservedCapacity = 1;
var $author$project$ElPriceConfiguration$ReservedWattage = 2;
var $author$project$ElPriceConfiguration$floatFieldFromString = function (str) {
	switch (str) {
		case 'main_breaker_rated_current':
			return $elm$core$Result$Ok(0);
		case 'reserved_capacity':
			return $elm$core$Result$Ok(1);
		case 'reserved_wattage':
			return $elm$core$Result$Ok(2);
		default:
			return $elm$core$Result$Err('unknown float field ' + str);
	}
};
var $author$project$ElPriceConfiguration$floatFieldKey = 'ff';
var $author$project$Intervals$Existing = function (a) {
	return {$: 0, a: a};
};
var $author$project$Intervals$New = {$: 1};
var $author$project$Intervals$idDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$andThen,
			function (s) {
				if (s === 'new') {
					return $elm$json$Json$Decode$succeed($author$project$Intervals$New);
				} else {
					return $elm$json$Json$Decode$fail('unknown IntervalId ' + s);
				}
			},
			$elm$json$Json$Decode$string),
			A2($elm$json$Json$Decode$map, $author$project$Intervals$Existing, $elm$json$Json$Decode$int)
		]));
var $author$project$ElPriceConfiguration$idFieldKey = 'i';
var $author$project$ElPriceConfiguration$parsedFloatKeyDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	$author$project$ElPriceConfiguration$floatFieldKey,
	A3(
		$elm$core$Basics$composeR,
		$elm$json$Json$Decode$map($author$project$ElPriceConfiguration$floatFieldFromString),
		$elm$json$Json$Decode$andThen($author$project$Enectiva$Conversion$decodeResult),
		$elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		$author$project$ElPriceConfiguration$idFieldKey,
		$author$project$Intervals$idDecoder,
		$elm$json$Json$Decode$succeed($elm$core$Tuple$pair)));
var $author$project$ElPriceConfiguration$parsedNumberMatcher = F2(
	function (value, number) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$ElPriceConfiguration$parsedFloatKeyDecoder, value);
		if (_v0.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v1 = _v0.a;
			var id = _v1.a;
			var field = _v1.b;
			return $elm$core$Maybe$Just(
				A3($author$project$ElPriceConfiguration$FloatParsed, id, field, number));
		}
	});
var $author$project$ElPriceConfiguration$subscriptions = function (_v0) {
	return A3(
		$author$project$Enectiva$I18n$parsed,
		$author$project$ElPriceConfiguration$IrrelevantPortMessaged,
		$author$project$ElPriceConfiguration$appName,
		A2($author$project$Enectiva$I18n$matchNumber, $author$project$ElPriceConfiguration$parsedNumberMatcher, $author$project$Enectiva$I18n$matchers));
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Ports$closeDialog = _Platform_outgoingPort('closeDialog', $elm$core$Basics$identity);
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$Ports$closeDialogWithoutEvent = function (payload) {
	return $author$project$Ports$closeDialog(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2('detail', payload)
				])));
};
var $author$project$ElPriceConfiguration$floatFieldToString = function (f) {
	switch (f) {
		case 0:
			return 'main_breaker_rated_current';
		case 1:
			return 'reserved_capacity';
		default:
			return 'reserved_wattage';
	}
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Intervals$idEncoder = function (id) {
	if (!id.$) {
		var idx = id.a;
		return $elm$json$Json$Encode$int(idx);
	} else {
		return $elm$json$Json$Encode$string('new');
	}
};
var $author$project$ElPriceConfiguration$floatPortKeyEncoder = F2(
	function (id, field) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					$author$project$ElPriceConfiguration$idFieldKey,
					$author$project$Intervals$idEncoder(id)),
					_Utils_Tuple2(
					$author$project$ElPriceConfiguration$floatFieldKey,
					$elm$json$Json$Encode$string(
						$author$project$ElPriceConfiguration$floatFieldToString(field)))
				]));
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Enectiva$I18n$ParseNumberArgs = function (a) {
	return {$: 1, a: a};
};
var $author$project$Enectiva$I18n$parse = _Platform_outgoingPort('parse', $elm$core$Basics$identity);
var $author$project$Enectiva$I18n$parseEncoder = F2(
	function (_v0, args) {
		var appName = _v0.a;
		var id = _v0.b;
		var _v1 = function () {
			if (args.$ === 1) {
				var str = args.a;
				return _Utils_Tuple2(
					'number',
					_List_fromArray(
						[
							_Utils_Tuple2(
							'value',
							$elm$json$Json$Encode$string(str))
						]));
			} else {
				var format = args.a;
				var str = args.b;
				return _Utils_Tuple2(
					'dateTime',
					_List_fromArray(
						[
							_Utils_Tuple2(
							'format',
							$elm$json$Json$Encode$string(format)),
							_Utils_Tuple2(
							'value',
							$elm$json$Json$Encode$string(str))
						]));
			}
		}();
		var tp = _v1.a;
		var kvPairs = _v1.b;
		return $elm$json$Json$Encode$object(
			_Utils_ap(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string(tp)),
						_Utils_Tuple2(
						'appName',
						$elm$json$Json$Encode$string(appName)),
						_Utils_Tuple2('id', id)
					]),
				kvPairs));
	});
var $author$project$Enectiva$I18n$parseNumber = F3(
	function (appName, id, str) {
		return $author$project$Enectiva$I18n$parse(
			A2(
				$author$project$Enectiva$I18n$parseEncoder,
				_Utils_Tuple2(appName, id),
				$author$project$Enectiva$I18n$ParseNumberArgs(str)));
	});
var $author$project$ServerErrors$JsonContent = function (a) {
	return {$: 0, a: a};
};
var $author$project$ElPriceConfiguration$SaveResponse = function (a) {
	return {$: 8, a: a};
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $turboMaCk$non_empty_list_alias$List$NonEmpty$toList = function (_v0) {
	var h = _v0.a;
	var t = _v0.b;
	return A2($elm$core$List$cons, h, t);
};
var $turboMaCk$non_empty_list_alias$List$NonEmpty$encodeList = function (f) {
	return A2(
		$elm$core$Basics$composeL,
		$elm$json$Json$Encode$list(f),
		$turboMaCk$non_empty_list_alias$List$NonEmpty$toList);
};
var $NoRedInk$elm_uuid$Prng$Uuid$toString = function (_v0) {
	var internalString = _v0;
	return internalString;
};
var $NoRedInk$elm_uuid$Prng$Uuid$encode = A2($elm$core$Basics$composeR, $NoRedInk$elm_uuid$Prng$Uuid$toString, $elm$json$Json$Encode$string);
var $author$project$ElPriceConfiguration$encodeMaybe = F2(
	function (enc, maybe) {
		if (maybe.$ === 1) {
			return $elm$json$Json$Encode$null;
		} else {
			var a = maybe.a;
			return enc(a);
		}
	});
var $elm$json$Json$Encode$float = _Json_wrap;
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {fq: d, f4: m, hd: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		ep: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		hd: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.hd, 0, date.ep);
};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.fq;
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.f4;
	});
var $justinmimbs$date$Date$monthNumber = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToNumber);
var $justinmimbs$date$Date$ordinalDay = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toOrdinalDate,
	function ($) {
		return $.ep;
	});
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $justinmimbs$date$Date$padSignedInt = F2(
	function (length, _int) {
		return _Utils_ap(
			(_int < 0) ? '-' : '',
			A3(
				$elm$core$String$padLeft,
				length,
				'0',
				$elm$core$String$fromInt(
					$elm$core$Basics$abs(_int))));
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		default:
			return 6;
	}
};
var $justinmimbs$date$Date$toWeekDate = function (_v0) {
	var rd = _v0;
	var wdn = $justinmimbs$date$Date$weekdayNumber(rd);
	var wy = $justinmimbs$date$Date$year(rd + (4 - wdn));
	var week1Day1 = $justinmimbs$date$Date$daysBeforeWeekYear(wy) + 1;
	return {
		g7: 1 + (((rd - week1Day1) / 7) | 0),
		g8: wy,
		jc: $justinmimbs$date$Date$numberToWeekday(wdn)
	};
};
var $justinmimbs$date$Date$weekNumber = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.g7;
	});
var $justinmimbs$date$Date$weekYear = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.g8;
	});
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $justinmimbs$date$Date$ordinalSuffix = function (n) {
	var nn = A2($elm$core$Basics$modBy, 100, n);
	var _v0 = A2(
		$elm$core$Basics$min,
		(nn < 20) ? nn : A2($elm$core$Basics$modBy, 10, nn),
		4);
	switch (_v0) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
var $justinmimbs$date$Date$withOrdinalSuffix = function (n) {
	return _Utils_ap(
		$elm$core$String$fromInt(n),
		$justinmimbs$date$Date$ordinalSuffix(n));
};
var $justinmimbs$date$Date$formatField = F4(
	function (language, _char, length, date) {
		switch (_char) {
			case 'y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$year(date));
				}
			case 'Y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekYear(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$weekYear(date));
				}
			case 'Q':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 3:
						return 'Q' + $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 4:
						return $justinmimbs$date$Date$withOrdinalSuffix(
							$justinmimbs$date$Date$quarter(date));
					case 5:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					default:
						return '';
				}
			case 'M':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$monthNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$monthNumber(date)));
					case 3:
						return language.dj(
							$justinmimbs$date$Date$month(date));
					case 4:
						return language.ej(
							$justinmimbs$date$Date$month(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.dj(
								$justinmimbs$date$Date$month(date)));
					default:
						return '';
				}
			case 'w':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekNumber(date)));
					default:
						return '';
				}
			case 'd':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$day(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(date)));
					case 3:
						return language.dL(
							$justinmimbs$date$Date$day(date));
					default:
						return '';
				}
			case 'D':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$ordinalDay(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					case 3:
						return A3(
							$elm$core$String$padLeft,
							3,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					default:
						return '';
				}
			case 'E':
				switch (length) {
					case 1:
						return language.bF(
							$justinmimbs$date$Date$weekday(date));
					case 2:
						return language.bF(
							$justinmimbs$date$Date$weekday(date));
					case 3:
						return language.bF(
							$justinmimbs$date$Date$weekday(date));
					case 4:
						return language.e7(
							$justinmimbs$date$Date$weekday(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.bF(
								$justinmimbs$date$Date$weekday(date)));
					case 6:
						return A2(
							$elm$core$String$left,
							2,
							language.bF(
								$justinmimbs$date$Date$weekday(date)));
					default:
						return '';
				}
			case 'e':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					default:
						return A4($justinmimbs$date$Date$formatField, language, 'E', length, date);
				}
			default:
				return '';
		}
	});
var $justinmimbs$date$Date$formatWithTokens = F3(
	function (language, tokens, date) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (token, formatted) {
					if (!token.$) {
						var _char = token.a;
						var length = token.b;
						return _Utils_ap(
							A4($justinmimbs$date$Date$formatField, language, _char, length, date),
							formatted);
					} else {
						var str = token.a;
						return _Utils_ap(str, formatted);
					}
				}),
			'',
			tokens);
	});
var $justinmimbs$date$Pattern$Literal = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$date$Pattern$escapedQuote = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$succeed(
		$justinmimbs$date$Pattern$Literal('\'')),
	$elm$parser$Parser$token('\'\''));
var $justinmimbs$date$Pattern$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.it, s);
};
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $justinmimbs$date$Pattern$fieldRepeats = function (str) {
	var _v0 = $elm$core$String$toList(str);
	if (_v0.b && (!_v0.b.b)) {
		var _char = _v0.a;
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F2(
						function (x, y) {
							return A2($justinmimbs$date$Pattern$Field, _char, 1 + (y - x));
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getOffset,
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(_char)))),
			$elm$parser$Parser$getOffset);
	} else {
		return $elm$parser$Parser$problem('expected exactly one char');
	}
};
var $justinmimbs$date$Pattern$field = A2(
	$elm$parser$Parser$andThen,
	$justinmimbs$date$Pattern$fieldRepeats,
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompIf($elm$core$Char$isAlpha)));
var $justinmimbs$date$Pattern$finalize = A2(
	$elm$core$List$foldl,
	F2(
		function (token, tokens) {
			var _v0 = _Utils_Tuple2(token, tokens);
			if (((_v0.a.$ === 1) && _v0.b.b) && (_v0.b.a.$ === 1)) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				var y = _v1.a.a;
				var rest = _v1.b;
				return A2(
					$elm$core$List$cons,
					$justinmimbs$date$Pattern$Literal(
						_Utils_ap(x, y)),
					rest);
			} else {
				return A2($elm$core$List$cons, token, tokens);
			}
		}),
	_List_Nil);
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $justinmimbs$date$Pattern$isLiteralChar = function (_char) {
	return (_char !== '\'') && (!$elm$core$Char$isAlpha(_char));
};
var $justinmimbs$date$Pattern$literal = A2(
	$elm$parser$Parser$map,
	$justinmimbs$date$Pattern$Literal,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($justinmimbs$date$Pattern$isLiteralChar)),
			$elm$parser$Parser$chompWhile($justinmimbs$date$Pattern$isLiteralChar))));
var $justinmimbs$date$Pattern$quotedHelp = function (result) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (str) {
					return $justinmimbs$date$Pattern$quotedHelp(
						_Utils_ap(result, str));
				},
				$elm$parser$Parser$getChompedString(
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed(0),
							$elm$parser$Parser$chompIf(
								$elm$core$Basics$neq('\''))),
						$elm$parser$Parser$chompWhile(
							$elm$core$Basics$neq('\''))))),
				A2(
				$elm$parser$Parser$andThen,
				function (_v0) {
					return $justinmimbs$date$Pattern$quotedHelp(result + '\'');
				},
				$elm$parser$Parser$token('\'\'')),
				$elm$parser$Parser$succeed(result)
			]));
};
var $justinmimbs$date$Pattern$quoted = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($justinmimbs$date$Pattern$Literal),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('\''))),
	A2(
		$elm$parser$Parser$ignorer,
		$justinmimbs$date$Pattern$quotedHelp(''),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('\'')),
					$elm$parser$Parser$end
				]))));
var $justinmimbs$date$Pattern$patternHelp = function (tokens) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (token) {
					return $justinmimbs$date$Pattern$patternHelp(
						A2($elm$core$List$cons, token, tokens));
				},
				$elm$parser$Parser$oneOf(
					_List_fromArray(
						[$justinmimbs$date$Pattern$field, $justinmimbs$date$Pattern$literal, $justinmimbs$date$Pattern$escapedQuote, $justinmimbs$date$Pattern$quoted]))),
				$elm$parser$Parser$lazy(
				function (_v0) {
					return $elm$parser$Parser$succeed(
						$justinmimbs$date$Pattern$finalize(tokens));
				})
			]));
};
var $justinmimbs$date$Pattern$fromString = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_fromArray(
			[
				$justinmimbs$date$Pattern$Literal(str)
			]),
		A2(
			$elm$parser$Parser$run,
			$justinmimbs$date$Pattern$patternHelp(_List_Nil),
			str));
};
var $justinmimbs$date$Date$formatWithLanguage = F2(
	function (language, pattern) {
		var tokens = $elm$core$List$reverse(
			$justinmimbs$date$Pattern$fromString(pattern));
		return A2($justinmimbs$date$Date$formatWithTokens, language, tokens);
	});
var $justinmimbs$date$Date$weekdayToName = function (wd) {
	switch (wd) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $justinmimbs$date$Date$language_en = {
	dL: $justinmimbs$date$Date$withOrdinalSuffix,
	ej: $justinmimbs$date$Date$monthToName,
	dj: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$monthToName,
		$elm$core$String$left(3)),
	e7: $justinmimbs$date$Date$weekdayToName,
	bF: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$weekdayToName,
		$elm$core$String$left(3))
};
var $justinmimbs$date$Date$format = function (pattern) {
	return A2($justinmimbs$date$Date$formatWithLanguage, $justinmimbs$date$Date$language_en, pattern);
};
var $justinmimbs$date$Date$toIsoString = $justinmimbs$date$Date$format('yyyy-MM-dd');
var $author$project$Enectiva$Conversion$stdFormatDate = $justinmimbs$date$Date$toIsoString;
var $author$project$Enectiva$DateRange$dateEncoder = A2($elm$core$Basics$composeR, $author$project$Enectiva$Conversion$stdFormatDate, $elm$json$Json$Encode$string);
var $author$project$Enectiva$DateRange$unboundedDateEncoder = function (date) {
	if (date.$ === 1) {
		var d = date.a;
		return $author$project$Enectiva$DateRange$dateEncoder(d);
	} else {
		return $elm$json$Json$Encode$null;
	}
};
var $author$project$Enectiva$DateRange$unboundedRangeEncoder = function (_v0) {
	var validFrom = _v0.a;
	var validTo = _v0.b;
	return _List_fromArray(
		[
			_Utils_Tuple2(
			'valid_from',
			$author$project$Enectiva$DateRange$unboundedDateEncoder(validFrom)),
			_Utils_Tuple2(
			'valid_to',
			$author$project$Enectiva$DateRange$unboundedDateEncoder(validTo))
		]);
};
var $author$project$ElPriceConfiguration$intervalEncoder = function (interval) {
	return $elm$json$Json$Encode$object(
		_Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$NoRedInk$elm_uuid$Prng$Uuid$encode(interval.dX)),
					_Utils_Tuple2(
					'main_breaker_rated_current',
					A2($author$project$ElPriceConfiguration$encodeMaybe, $elm$json$Json$Encode$float, interval.bt)),
					_Utils_Tuple2(
					'number_of_main_breaker_phases',
					A2($author$project$ElPriceConfiguration$encodeMaybe, $elm$json$Json$Encode$int, interval.bY)),
					_Utils_Tuple2(
					'distribution_rate',
					A2($author$project$ElPriceConfiguration$encodeMaybe, $elm$json$Json$Encode$string, interval.bJ)),
					_Utils_Tuple2(
					'reserved_capacity',
					A2($author$project$ElPriceConfiguration$encodeMaybe, $elm$json$Json$Encode$float, interval.bx)),
					_Utils_Tuple2(
					'reserved_wattage',
					A2($author$project$ElPriceConfiguration$encodeMaybe, $elm$json$Json$Encode$float, interval.by))
				]),
			$author$project$Enectiva$DateRange$unboundedRangeEncoder(interval.gy)));
};
var $author$project$ElPriceConfiguration$intervalsEncoder = function (intervals) {
	return A2($turboMaCk$non_empty_list_alias$List$NonEmpty$encodeList, $author$project$ElPriceConfiguration$intervalEncoder, intervals);
};
var $author$project$ElPriceConfiguration$encoder = function (intervals) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'intervals',
				$author$project$ElPriceConfiguration$intervalsEncoder(intervals))
			]));
};
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $author$project$ServerErrors$General = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $author$project$ServerErrors$PerField = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Timeout = {$: 1};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $author$project$ServerErrors$errorsDecoder = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['errors', 'validation']),
	$elm$json$Json$Decode$dict(
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string)));
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $author$project$Enectiva$HttpExtra$errorToString = function (err) {
	switch (err.$) {
		case 0:
			var str = err.a;
			return 'Bad URL ' + str;
		case 1:
			return 'Timeout';
		case 2:
			return 'Network error';
		case 3:
			return 'Bad status';
		default:
			var str = err.a;
			return 'Bad body ' + str;
	}
};
var $author$project$ServerErrors$generalError = A2(
	$elm$core$Basics$composeR,
	$author$project$Enectiva$HttpExtra$errorToString,
	A2($elm$core$Basics$composeR, $author$project$ServerErrors$General, $elm$core$Result$Err));
var $author$project$ServerErrors$httpClientSideError = function (code) {
	return (400 <= code) && (code < 500);
};
var $author$project$ServerErrors$expectResponse = F2(
	function (tagger, expectation) {
		return A2(
			$elm$http$Http$expectStringResponse,
			tagger,
			function (response) {
				switch (response.$) {
					case 0:
						var u = response.a;
						return $author$project$ServerErrors$generalError(
							$elm$http$Http$BadUrl(u));
					case 1:
						return $author$project$ServerErrors$generalError($elm$http$Http$Timeout);
					case 2:
						return $author$project$ServerErrors$generalError($elm$http$Http$NetworkError);
					case 3:
						var metadata = response.a;
						var body = response.b;
						if ($author$project$ServerErrors$httpClientSideError(metadata.gR)) {
							var _v1 = A2($elm$json$Json$Decode$decodeString, $author$project$ServerErrors$errorsDecoder, body);
							if (!_v1.$) {
								var se = _v1.a;
								return $elm$core$Result$Err(
									$author$project$ServerErrors$PerField(se));
							} else {
								var de = _v1.a;
								return $elm$core$Result$Err(
									$author$project$ServerErrors$General(
										$elm$json$Json$Decode$errorToString(de)));
							}
						} else {
							return $author$project$ServerErrors$generalError(
								$elm$http$Http$BadStatus(metadata.gR));
						}
					default:
						var body = response.b;
						if (expectation.$ === 1) {
							var v = expectation.a;
							return $elm$core$Result$Ok(v);
						} else {
							var decoder = expectation.a;
							var _v3 = A2($elm$json$Json$Decode$decodeString, decoder, body);
							if (!_v3.$) {
								var value = _v3.a;
								return $elm$core$Result$Ok(value);
							} else {
								var err = _v3.a;
								return $author$project$ServerErrors$generalError(
									$elm$http$Http$BadBody(
										$elm$json$Json$Decode$errorToString(err)));
							}
						}
				}
			});
	});
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {gB: reqs, gV: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.i6;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.gB));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.gV)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					hi: r.hi,
					hq: r.hq,
					hS: A2(_Http_mapExpect, func, r.hS),
					hZ: r.hZ,
					id: r.id,
					i_: r.i_,
					i6: r.i6,
					i9: r.i9
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{hi: false, hq: r.hq, hS: r.hS, hZ: r.hZ, id: r.id, i_: r.i_, i6: r.i6, i9: r.i9}));
};
var $author$project$ElPriceConfiguration$save = F3(
	function (csrfToken, updateUrl, intervals) {
		return $elm$http$Http$request(
			{
				hq: $elm$http$Http$jsonBody(
					$author$project$ElPriceConfiguration$encoder(intervals)),
				hS: A2(
					$author$project$ServerErrors$expectResponse,
					$author$project$ElPriceConfiguration$SaveResponse,
					$author$project$ServerErrors$JsonContent($elm$json$Json$Decode$value)),
				hZ: _List_fromArray(
					[
						A2($elm$http$Http$header, 'X-CSRF-Token', csrfToken),
						A2($elm$http$Http$header, 'X-Requested-With', 'XMLHttpRequest')
					]),
				id: 'PATCH',
				i_: $elm$core$Maybe$Nothing,
				i6: $elm$core$Maybe$Nothing,
				i9: updateUrl
			});
	});
var $author$project$ElPriceConfiguration$effectToCmd = function (_v0) {
	var model = _v0.a;
	var effect = _v0.b;
	switch (effect.$) {
		case 0:
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		case 1:
			var id = effect.a;
			var field = effect.b;
			var value = effect.c;
			return _Utils_Tuple2(
				model,
				A3(
					$author$project$Enectiva$I18n$parseNumber,
					$author$project$ElPriceConfiguration$appName,
					A2($author$project$ElPriceConfiguration$floatPortKeyEncoder, id, field),
					value));
		case 2:
			return _Utils_Tuple2(
				model,
				$author$project$Ports$closeDialogWithoutEvent($elm$json$Json$Encode$null));
		case 4:
			var cv = effect.a;
			return _Utils_Tuple2(
				model,
				$author$project$Ports$closeDialogWithoutEvent(cv));
		default:
			var updateUrl = effect.a;
			var intervals = effect.b;
			return _Utils_Tuple2(
				model,
				A3($author$project$ElPriceConfiguration$save, model.ck, updateUrl, intervals));
	}
};
var $author$project$ElPriceConfiguration$CloseDialog = {$: 2};
var $author$project$AreaForm$Inner$Failure = function (a) {
	return {$: 3, a: a};
};
var $author$project$AreaForm$Inner$Loading = {$: 1};
var $author$project$AreaForm$Inner$Success = function (a) {
	return {$: 2, a: a};
};
var $author$project$ElPriceConfiguration$UpdateCurrentIntervalAndCloseDialog = function (a) {
	return {$: 4, a: a};
};
var $justinmimbs$date$Date$compare = F2(
	function (_v0, _v1) {
		var a = _v0;
		var b = _v1;
		return A2($elm$core$Basics$compare, a, b);
	});
var $author$project$Enectiva$DateRange$setFrom = F2(
	function (newFrom, _v0) {
		var oldFrom = _v0.a;
		var to = _v0.b;
		var _v1 = _Utils_Tuple2(newFrom, to);
		if ((_v1.a.$ === 1) && (_v1.b.$ === 1)) {
			var a = _v1.a.a;
			var b = _v1.b.a;
			var _v2 = A2($justinmimbs$date$Date$compare, a, b);
			if (_v2 === 2) {
				return _Utils_Tuple2(oldFrom, to);
			} else {
				return _Utils_Tuple2(newFrom, to);
			}
		} else {
			return _Utils_Tuple2(newFrom, to);
		}
	});
var $author$project$Enectiva$DateRange$setBoundedFrom = function (date) {
	return $author$project$Enectiva$DateRange$setFrom(
		$author$project$Enectiva$DateRange$BoundedDate(date));
};
var $author$project$Enectiva$DateRange$sinceForeverUntilForever = _Utils_Tuple2(
	$author$project$Enectiva$DateRange$Infinity(0),
	$author$project$Enectiva$DateRange$Infinity(0));
var $author$project$ElPriceConfiguration$emptyInterval = F3(
	function (today, uuid, interval) {
		var range = A2($author$project$Enectiva$DateRange$setBoundedFrom, today, $author$project$Enectiva$DateRange$sinceForeverUntilForever);
		return _Utils_update(
			interval,
			{dX: uuid, gy: range});
	});
var $author$project$ElPriceConfiguration$firstOfMonth = function (date) {
	return A3(
		$justinmimbs$date$Date$fromCalendarDate,
		$justinmimbs$date$Date$year(date),
		$justinmimbs$date$Date$month(date),
		1);
};
var $NoRedInk$elm_random_general$Random$General$Generator = $elm$core$Basics$identity;
var $NoRedInk$elm_random_general$Random$General$map = F2(
	function (func, _v0) {
		var genA = _v0;
		return function (seed0) {
			var _v1 = genA(seed0);
			var a = _v1.a;
			var seed1 = _v1.b;
			return _Utils_Tuple2(
				func(a),
				seed1);
		};
	});
var $NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$map = $NoRedInk$elm_random_general$Random$General$map;
var $NoRedInk$elm_random_general$Random$General$Config = $elm$core$Basics$identity;
var $NoRedInk$elm_random_general$Random$General$makeConfig = F2(
	function (next, peel) {
		return {cD: next, cG: peel};
	});
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $elm$core$Elm$JsArray$unsafeSet = _JsArray_unsafeSet;
var $elm$core$Array$setHelp = F4(
	function (shift, index, value, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
		if (!_v0.$) {
			var subTree = _v0.a;
			var newSub = A4($elm$core$Array$setHelp, shift - $elm$core$Array$shiftStep, index, value, subTree);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$SubTree(newSub),
				tree);
		} else {
			var values = _v0.a;
			var newLeaf = A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, values);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$Leaf(newLeaf),
				tree);
		}
	});
var $elm$core$Array$set = F3(
	function (index, value, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? array : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			tree,
			A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, tail)) : A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A4($elm$core$Array$setHelp, startShift, index, value, tree),
			tail));
	});
var $NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$incrementExtensionHelp = F3(
	function (index, prev, arr) {
		incrementExtensionHelp:
		while (true) {
			if (!prev) {
				var _v0 = A2($elm$core$Array$get, index, arr);
				if (!_v0.$) {
					var elem = _v0.a;
					var newElem = (elem + 1013904223) >>> 0;
					var $temp$index = index + 1,
						$temp$prev = newElem,
						$temp$arr = A3($elm$core$Array$set, index, newElem, arr);
					index = $temp$index;
					prev = $temp$prev;
					arr = $temp$arr;
					continue incrementExtensionHelp;
				} else {
					return arr;
				}
			} else {
				return arr;
			}
		}
	});
var $NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$incrementExtension = function (arr) {
	return A3($NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$incrementExtensionHelp, 0, 0, arr);
};
var $NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$next = function (_v0) {
	var s = _v0;
	var newBase = $NoRedInk$elm_random_pcg_extended$Internal$Pcg$next(s.a_);
	var baseState = newBase.a;
	return {
		a_: newBase,
		ay: (!baseState) ? $NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$incrementExtension(s.ay) : s.ay
	};
};
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $NoRedInk$elm_random_pcg_extended$Internal$Pcg$peel = function (_v0) {
	var state = _v0.a;
	var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
	return ((word >>> 22) ^ word) >>> 0;
};
var $NoRedInk$elm_random_pcg_extended$Internal$Pcg$config = A2($NoRedInk$elm_random_general$Random$General$makeConfig, $NoRedInk$elm_random_pcg_extended$Internal$Pcg$next, $NoRedInk$elm_random_pcg_extended$Internal$Pcg$peel);
var $NoRedInk$elm_random_general$Random$General$int = F3(
	function (_v0, a, b) {
		var c = _v0;
		return function (seed0) {
			var _v1 = (_Utils_cmp(a, b) < 0) ? _Utils_Tuple2(a, b) : _Utils_Tuple2(b, a);
			var lo = _v1.a;
			var hi = _v1.b;
			var range = (hi - lo) + 1;
			if (!((range - 1) & range)) {
				return _Utils_Tuple2(
					(((range - 1) & c.cG(seed0)) >>> 0) + lo,
					c.cD(seed0));
			} else {
				var threshhold = (((-range) >>> 0) % range) >>> 0;
				var accountForBias = function (seed) {
					accountForBias:
					while (true) {
						var x = c.cG(seed);
						var seedN = c.cD(seed);
						if (_Utils_cmp(x, threshhold) < 0) {
							var $temp$seed = seedN;
							seed = $temp$seed;
							continue accountForBias;
						} else {
							return _Utils_Tuple2((x % range) + lo, seedN);
						}
					}
				};
				return accountForBias(seed0);
			}
		};
	});
var $NoRedInk$elm_random_pcg_extended$Internal$Pcg$int = $NoRedInk$elm_random_general$Random$General$int($NoRedInk$elm_random_pcg_extended$Internal$Pcg$config);
var $elm$core$Array$length = function (_v0) {
	var len = _v0.a;
	return len;
};
var $NoRedInk$elm_random_general$Random$General$step = F2(
	function (_v0, seed) {
		var gen = _v0;
		return gen(seed);
	});
var $NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$peel = function (_v0) {
	var s = _v0;
	var baseOut = $NoRedInk$elm_random_pcg_extended$Internal$Pcg$peel(s.a_);
	var _v1 = A2(
		$NoRedInk$elm_random_general$Random$General$step,
		A2(
			$NoRedInk$elm_random_pcg_extended$Internal$Pcg$int,
			0,
			$elm$core$Array$length(s.ay) - 1),
		s.a_);
	var randIndex = _v1.a;
	var extension = A2(
		$elm$core$Maybe$withDefault,
		0,
		A2($elm$core$Array$get, randIndex, s.ay));
	return (baseOut ^ extension) >>> 0;
};
var $NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$config = A2($NoRedInk$elm_random_general$Random$General$makeConfig, $NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$next, $NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$peel);
var $NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$int = $NoRedInk$elm_random_general$Random$General$int($NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$config);
var $NoRedInk$elm_uuid$Prng$Uuid$hexGenerator = A2($NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$int, 0, 15);
var $NoRedInk$elm_random_general$Random$General$listHelp = F4(
	function (list_, n, generate, seed) {
		listHelp:
		while (true) {
			if (n < 1) {
				return _Utils_Tuple2(list_, seed);
			} else {
				var _v0 = generate(seed);
				var value = _v0.a;
				var newSeed = _v0.b;
				var $temp$list_ = A2($elm$core$List$cons, value, list_),
					$temp$n = n - 1,
					$temp$generate = generate,
					$temp$seed = newSeed;
				list_ = $temp$list_;
				n = $temp$n;
				generate = $temp$generate;
				seed = $temp$seed;
				continue listHelp;
			}
		}
	});
var $NoRedInk$elm_random_general$Random$General$list = F2(
	function (n, _v0) {
		var generate = _v0;
		return function (seed) {
			return A4($NoRedInk$elm_random_general$Random$General$listHelp, _List_Nil, n, generate, seed);
		};
	});
var $NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$list = $NoRedInk$elm_random_general$Random$General$list;
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$String$fromList = _String_fromList;
var $elm$core$Bitwise$or = _Bitwise_or;
var $NoRedInk$elm_uuid$Prng$Uuid$Barebones$limitDigitRange8ToB = function (digit) {
	return (digit & 3) | 8;
};
var $elm$core$Char$fromCode = _Char_fromCode;
var $NoRedInk$elm_uuid$Prng$Uuid$Barebones$hexDigits = function () {
	var mapChars = F2(
		function (offset, digit) {
			return $elm$core$Char$fromCode(digit + offset);
		});
	return $elm$core$Array$fromList(
		_Utils_ap(
			A2(
				$elm$core$List$map,
				mapChars(48),
				A2($elm$core$List$range, 0, 9)),
			A2(
				$elm$core$List$map,
				mapChars(97),
				A2($elm$core$List$range, 0, 5))));
}();
var $NoRedInk$elm_uuid$Prng$Uuid$Barebones$mapToHex = function (index) {
	var maybeResult = A2($elm$core$Array$get, index, $NoRedInk$elm_uuid$Prng$Uuid$Barebones$hexDigits);
	if (maybeResult.$ === 1) {
		return 'x';
	} else {
		var result = maybeResult.a;
		return result;
	}
};
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $NoRedInk$elm_uuid$Prng$Uuid$Barebones$toUuidString = function (thirtyOneHexDigits) {
	return $elm$core$String$concat(
		_List_fromArray(
			[
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$NoRedInk$elm_uuid$Prng$Uuid$Barebones$mapToHex,
					A2($elm$core$List$take, 8, thirtyOneHexDigits))),
				'-',
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$NoRedInk$elm_uuid$Prng$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$take,
						4,
						A2($elm$core$List$drop, 8, thirtyOneHexDigits)))),
				'-',
				'4',
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$NoRedInk$elm_uuid$Prng$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$take,
						3,
						A2($elm$core$List$drop, 12, thirtyOneHexDigits)))),
				'-',
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$NoRedInk$elm_uuid$Prng$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$map,
						$NoRedInk$elm_uuid$Prng$Uuid$Barebones$limitDigitRange8ToB,
						A2(
							$elm$core$List$take,
							1,
							A2($elm$core$List$drop, 15, thirtyOneHexDigits))))),
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$NoRedInk$elm_uuid$Prng$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$take,
						3,
						A2($elm$core$List$drop, 16, thirtyOneHexDigits)))),
				'-',
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$NoRedInk$elm_uuid$Prng$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$take,
						12,
						A2($elm$core$List$drop, 19, thirtyOneHexDigits))))
			]));
};
var $NoRedInk$elm_uuid$Prng$Uuid$stringGenerator = A2(
	$NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$map,
	$NoRedInk$elm_uuid$Prng$Uuid$Barebones$toUuidString,
	A2($NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$list, 31, $NoRedInk$elm_uuid$Prng$Uuid$hexGenerator));
var $NoRedInk$elm_uuid$Prng$Uuid$generator = A2($NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$map, $elm$core$Basics$identity, $NoRedInk$elm_uuid$Prng$Uuid$stringGenerator);
var $turboMaCk$non_empty_list_alias$List$NonEmpty$lastHelper = function (xs) {
	lastHelper:
	while (true) {
		if (!xs.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!xs.b.b) {
				var h = xs.a;
				return $elm$core$Maybe$Just(h);
			} else {
				var t = xs.b;
				var $temp$xs = t;
				xs = $temp$xs;
				continue lastHelper;
			}
		}
	}
};
var $turboMaCk$non_empty_list_alias$List$NonEmpty$last = function (_v0) {
	var h = _v0.a;
	var t = _v0.b;
	return A2(
		$elm$core$Maybe$withDefault,
		h,
		$turboMaCk$non_empty_list_alias$List$NonEmpty$lastHelper(t));
};
var $turboMaCk$non_empty_list_alias$List$NonEmpty$cons = F2(
	function (a, _v0) {
		var h = _v0.a;
		var t = _v0.b;
		return _Utils_Tuple2(
			a,
			A2($elm$core$List$cons, h, t));
	});
var $turboMaCk$non_empty_list_alias$List$NonEmpty$foldl = F2(
	function (f, acc) {
		return A2(
			$elm$core$Basics$composeL,
			A2($elm$core$List$foldl, f, acc),
			$turboMaCk$non_empty_list_alias$List$NonEmpty$toList);
	});
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $turboMaCk$non_empty_list_alias$List$NonEmpty$reverse = function (_v0) {
	var h = _v0.a;
	var t = _v0.b;
	var _v1 = $elm$core$List$reverse(
		A2($elm$core$List$cons, h, t));
	if (!_v1.b) {
		return _Utils_Tuple2(h, _List_Nil);
	} else {
		var nH = _v1.a;
		var nT = _v1.b;
		return _Utils_Tuple2(nH, nT);
	}
};
var $turboMaCk$non_empty_list_alias$List$NonEmpty$singleton = function (h) {
	return _Utils_Tuple2(h, _List_Nil);
};
var $elm$core$Dict$singleton = F2(
	function (key, value) {
		return A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
	});
var $elm$core$Set$singleton = function (key) {
	return A2($elm$core$Dict$singleton, key, 0);
};
var $NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$step = $NoRedInk$elm_random_general$Random$General$step;
var $turboMaCk$non_empty_list_alias$List$NonEmpty$uncons = function (_v0) {
	var h = _v0.a;
	var t = _v0.b;
	return _Utils_Tuple2(
		h,
		$turboMaCk$non_empty_list_alias$List$NonEmpty$fromList(t));
};
var $author$project$Intervals$ensureUniqueUuids = F2(
	function (initialSeed, intervals) {
		var _v0 = $turboMaCk$non_empty_list_alias$List$NonEmpty$uncons(intervals);
		if (!_v0.b.$) {
			var firstInterval = _v0.a;
			var otherIntervals = _v0.b.a;
			return function (_v3) {
				var seed = _v3.a;
				var updatedIntervals = _v3.b;
				return _Utils_Tuple2(
					seed,
					$turboMaCk$non_empty_list_alias$List$NonEmpty$reverse(updatedIntervals));
			}(
				A3(
					$turboMaCk$non_empty_list_alias$List$NonEmpty$foldl,
					F2(
						function (interval, _v1) {
							var seed = _v1.a;
							var acc = _v1.b;
							var usedUuids = _v1.c;
							var uuid = $NoRedInk$elm_uuid$Prng$Uuid$toString(interval.dX);
							if (A2($elm$core$Set$member, uuid, usedUuids)) {
								var _v2 = A2($NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$step, $NoRedInk$elm_uuid$Prng$Uuid$generator, seed);
								var newUuid = _v2.a;
								var newSeed = _v2.b;
								return _Utils_Tuple3(
									newSeed,
									A2(
										$turboMaCk$non_empty_list_alias$List$NonEmpty$cons,
										_Utils_update(
											interval,
											{dX: newUuid}),
										acc),
									usedUuids);
							} else {
								return _Utils_Tuple3(
									seed,
									A2($turboMaCk$non_empty_list_alias$List$NonEmpty$cons, interval, acc),
									A2($elm$core$Set$insert, uuid, usedUuids));
							}
						}),
					_Utils_Tuple3(
						initialSeed,
						$turboMaCk$non_empty_list_alias$List$NonEmpty$singleton(firstInterval),
						$elm$core$Set$singleton(
							$NoRedInk$elm_uuid$Prng$Uuid$toString(firstInterval.dX))),
					otherIntervals));
		} else {
			var _v4 = _v0.b;
			return _Utils_Tuple2(initialSeed, intervals);
		}
	});
var $author$project$Enectiva$DateRange$mapUnboundedRange = F5(
	function (infinity, fromUnbounded, toUnbounded, bounded, range) {
		if (!range.a.$) {
			if (!range.b.$) {
				var _v1 = range.a.a;
				var _v2 = range.b.a;
				return infinity(0);
			} else {
				var _v3 = range.a.a;
				var to = range.b.a;
				return fromUnbounded(to);
			}
		} else {
			if (!range.b.$) {
				var from = range.a.a;
				var _v4 = range.b.a;
				return toUnbounded(from);
			} else {
				var from = range.a.a;
				var to = range.b.a;
				return A2(bounded, from, to);
			}
		}
	});
var $turboMaCk$non_empty_list_alias$List$NonEmpty$foldr = F2(
	function (f, acc) {
		return A2(
			$elm$core$Basics$composeL,
			A2($elm$core$List$foldr, f, acc),
			$turboMaCk$non_empty_list_alias$List$NonEmpty$toList);
	});
var $turboMaCk$non_empty_list_alias$List$NonEmpty$append = F2(
	function (ne1, ne2) {
		var _v0 = $turboMaCk$non_empty_list_alias$List$NonEmpty$toList(ne2);
		if (!_v0.b) {
			return ne1;
		} else {
			return A3($turboMaCk$non_empty_list_alias$List$NonEmpty$foldr, $turboMaCk$non_empty_list_alias$List$NonEmpty$cons, ne2, ne1);
		}
	});
var $turboMaCk$non_empty_list_alias$List$NonEmpty$fromCons = $elm$core$Tuple$pair;
var $justinmimbs$date$Date$Days = 3;
var $justinmimbs$date$Date$Months = 1;
var $justinmimbs$date$Date$add = F3(
	function (unit, n, _v0) {
		var rd = _v0;
		switch (unit) {
			case 0:
				return A3($justinmimbs$date$Date$add, 1, 12 * n, rd);
			case 1:
				var date = $justinmimbs$date$Date$toCalendarDate(rd);
				var wholeMonths = ((12 * (date.hd - 1)) + ($justinmimbs$date$Date$monthToNumber(date.f4) - 1)) + n;
				var m = $justinmimbs$date$Date$numberToMonth(
					A2($elm$core$Basics$modBy, 12, wholeMonths) + 1);
				var y = A2($justinmimbs$date$Date$floorDiv, wholeMonths, 12) + 1;
				return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A2(
					$elm$core$Basics$min,
					date.fq,
					A2($justinmimbs$date$Date$daysInMonth, y, m));
			case 2:
				return rd + (7 * n);
			default:
				return rd + n;
		}
	});
var $author$project$Intervals$shortenIntervalAfter = F2(
	function (interval, previousEnd) {
		return _Utils_update(
			interval,
			{
				gy: A2(
					$author$project$Enectiva$DateRange$setBoundedFrom,
					A3($justinmimbs$date$Date$add, 3, 1, previousEnd),
					interval.gy)
			});
	});
var $author$project$Enectiva$DateRange$setTo = F2(
	function (newTo, _v0) {
		var from = _v0.a;
		var oldTo = _v0.b;
		var _v1 = _Utils_Tuple2(from, newTo);
		if ((_v1.a.$ === 1) && (_v1.b.$ === 1)) {
			var a = _v1.a.a;
			var b = _v1.b.a;
			var _v2 = A2($justinmimbs$date$Date$compare, a, b);
			if (_v2 === 2) {
				return _Utils_Tuple2(from, oldTo);
			} else {
				return _Utils_Tuple2(from, newTo);
			}
		} else {
			return _Utils_Tuple2(from, newTo);
		}
	});
var $author$project$Enectiva$DateRange$setBoundedTo = function (date) {
	return $author$project$Enectiva$DateRange$setTo(
		$author$project$Enectiva$DateRange$BoundedDate(date));
};
var $author$project$Intervals$shortenIntervalUpTo = F2(
	function (interval, nextStart) {
		return _Utils_update(
			interval,
			{
				gy: A2(
					$author$project$Enectiva$DateRange$setBoundedTo,
					A3($justinmimbs$date$Date$add, 3, -1, nextStart),
					interval.gy)
			});
	});
var $author$project$Intervals$splitIntervalAround = F2(
	function (interval, _v0) {
		var from = _v0.a;
		var to = _v0.b;
		return A2(
			$turboMaCk$non_empty_list_alias$List$NonEmpty$fromCons,
			A2($author$project$Intervals$shortenIntervalAfter, interval, to),
			_List_fromArray(
				[
					A2($author$project$Intervals$shortenIntervalUpTo, interval, from)
				]));
	});
var $author$project$Intervals$useIntervalSegmentsAround = F3(
	function (_v0, interval, newList) {
		var newFrom = _v0.a;
		var newTo = _v0.b;
		return A2(
			$turboMaCk$non_empty_list_alias$List$NonEmpty$append,
			A2(
				$author$project$Intervals$splitIntervalAround,
				interval,
				_Utils_Tuple2(newFrom, newTo)),
			newList);
	});
var $author$project$Intervals$useIntervalShortenedOnTheLeft = F2(
	function (newTo, interval) {
		return $turboMaCk$non_empty_list_alias$List$NonEmpty$cons(
			A2($author$project$Intervals$shortenIntervalAfter, interval, newTo));
	});
var $author$project$Intervals$useIntervalShortenedOnTheRight = F2(
	function (newFrom, interval) {
		return $turboMaCk$non_empty_list_alias$List$NonEmpty$cons(
			A2($author$project$Intervals$shortenIntervalUpTo, interval, newFrom));
	});
var $author$project$Intervals$useIntervalUnchanged = $turboMaCk$non_empty_list_alias$List$NonEmpty$cons;
var $author$project$Intervals$foldIntervalWithFullyBoundedRoot = F4(
	function (newFrom, newTo, interval, newList) {
		return A5(
			$author$project$Enectiva$DateRange$mapUnboundedRange,
			$elm$core$Basics$always(
				A3(
					$author$project$Intervals$useIntervalSegmentsAround,
					_Utils_Tuple2(newFrom, newTo),
					interval,
					newList)),
			function (to) {
				var _v0 = _Utils_Tuple2(
					A2($justinmimbs$date$Date$compare, newFrom, to),
					A2($justinmimbs$date$Date$compare, newTo, to));
				_v0$2:
				while (true) {
					switch (_v0.a) {
						case 0:
							if (!_v0.b) {
								var _v1 = _v0.a;
								var _v2 = _v0.b;
								return A3(
									$author$project$Intervals$useIntervalSegmentsAround,
									_Utils_Tuple2(newFrom, newTo),
									interval,
									newList);
							} else {
								break _v0$2;
							}
						case 2:
							if (_v0.b === 2) {
								var _v3 = _v0.a;
								var _v4 = _v0.b;
								return A2($author$project$Intervals$useIntervalUnchanged, interval, newList);
							} else {
								break _v0$2;
							}
						default:
							break _v0$2;
					}
				}
				return A3($author$project$Intervals$useIntervalShortenedOnTheRight, newFrom, interval, newList);
			},
			function (from) {
				var _v5 = _Utils_Tuple2(
					A2($justinmimbs$date$Date$compare, newFrom, from),
					A2($justinmimbs$date$Date$compare, newTo, from));
				_v5$2:
				while (true) {
					switch (_v5.a) {
						case 2:
							if (_v5.b === 2) {
								var _v6 = _v5.a;
								var _v7 = _v5.b;
								return A3(
									$author$project$Intervals$useIntervalSegmentsAround,
									_Utils_Tuple2(newFrom, newTo),
									interval,
									newList);
							} else {
								break _v5$2;
							}
						case 0:
							if (!_v5.b) {
								var _v8 = _v5.a;
								var _v9 = _v5.b;
								return A2($author$project$Intervals$useIntervalUnchanged, interval, newList);
							} else {
								break _v5$2;
							}
						default:
							break _v5$2;
					}
				}
				return A3($author$project$Intervals$useIntervalShortenedOnTheLeft, newTo, interval, newList);
			},
			F2(
				function (from, to) {
					var _v10 = _Utils_Tuple2(
						_Utils_Tuple2(
							A2($justinmimbs$date$Date$compare, newTo, from),
							A2($justinmimbs$date$Date$compare, newFrom, from)),
						_Utils_Tuple2(
							A2($justinmimbs$date$Date$compare, newTo, to),
							A2($justinmimbs$date$Date$compare, newFrom, to)));
					_v10$0:
					while (true) {
						_v10$2:
						while (true) {
							switch (_v10.a.b) {
								case 0:
									switch (_v10.a.a) {
										case 0:
											break _v10$0;
										case 1:
											break _v10$2;
										default:
											switch (_v10.b.a) {
												case 0:
													var _v21 = _v10.a;
													var _v22 = _v21.a;
													var _v23 = _v21.b;
													var _v24 = _v10.b;
													var _v25 = _v24.a;
													return A3($author$project$Intervals$useIntervalShortenedOnTheLeft, newTo, interval, newList);
												case 1:
													var _v26 = _v10.a;
													var _v27 = _v26.a;
													var _v28 = _v26.b;
													var _v29 = _v10.b;
													var _v30 = _v29.a;
													return newList;
												default:
													var _v31 = _v10.a;
													var _v32 = _v31.a;
													var _v33 = _v31.b;
													var _v34 = _v10.b;
													var _v35 = _v34.a;
													return newList;
											}
									}
								case 1:
									switch (_v10.a.a) {
										case 0:
											break _v10$0;
										case 1:
											if ((_v10.b.a === 1) && (_v10.b.b === 1)) {
												var _v13 = _v10.a;
												var _v14 = _v13.a;
												var _v15 = _v13.b;
												var _v16 = _v10.b;
												var _v17 = _v16.a;
												var _v18 = _v16.b;
												return newList;
											} else {
												break _v10$2;
											}
										default:
											switch (_v10.b.a) {
												case 0:
													var _v36 = _v10.a;
													var _v37 = _v36.a;
													var _v38 = _v36.b;
													var _v39 = _v10.b;
													var _v40 = _v39.a;
													return A3($author$project$Intervals$useIntervalShortenedOnTheLeft, newTo, interval, newList);
												case 1:
													var _v41 = _v10.a;
													var _v42 = _v41.a;
													var _v43 = _v41.b;
													var _v44 = _v10.b;
													var _v45 = _v44.a;
													return newList;
												default:
													var _v46 = _v10.a;
													var _v47 = _v46.a;
													var _v48 = _v46.b;
													var _v49 = _v10.b;
													var _v50 = _v49.a;
													return newList;
											}
									}
								default:
									switch (_v10.b.a) {
										case 0:
											switch (_v10.a.a) {
												case 0:
													break _v10$0;
												case 1:
													break _v10$2;
												default:
													var _v51 = _v10.a;
													var _v52 = _v51.b;
													var _v53 = _v10.b;
													var _v54 = _v53.a;
													return A3(
														$author$project$Intervals$useIntervalSegmentsAround,
														_Utils_Tuple2(newFrom, newTo),
														interval,
														newList);
											}
										case 1:
											switch (_v10.a.a) {
												case 0:
													break _v10$0;
												case 1:
													break _v10$2;
												default:
													var _v55 = _v10.a;
													var _v56 = _v55.b;
													var _v57 = _v10.b;
													var _v58 = _v57.a;
													return A3($author$project$Intervals$useIntervalShortenedOnTheRight, newFrom, interval, newList);
											}
										default:
											switch (_v10.a.a) {
												case 0:
													break _v10$0;
												case 1:
													break _v10$2;
												default:
													if (_v10.b.b === 2) {
														var _v59 = _v10.a;
														var _v60 = _v59.b;
														var _v61 = _v10.b;
														var _v62 = _v61.a;
														var _v63 = _v61.b;
														return A2($author$project$Intervals$useIntervalUnchanged, interval, newList);
													} else {
														var _v64 = _v10.a;
														var _v65 = _v64.b;
														var _v66 = _v10.b;
														var _v67 = _v66.a;
														return A3($author$project$Intervals$useIntervalShortenedOnTheRight, newFrom, interval, newList);
													}
											}
									}
							}
						}
						var _v19 = _v10.a;
						var _v20 = _v19.a;
						return A3($author$project$Intervals$useIntervalShortenedOnTheLeft, newTo, interval, newList);
					}
					var _v11 = _v10.a;
					var _v12 = _v11.a;
					return A2($author$project$Intervals$useIntervalUnchanged, interval, newList);
				}),
			interval.gy);
	});
var $author$project$Intervals$skipInterval = $elm$core$Basics$identity;
var $author$project$Intervals$foldIntervalWithLeftBoundedRoot = F3(
	function (newFrom, interval, newList) {
		return A5(
			$author$project$Enectiva$DateRange$mapUnboundedRange,
			$elm$core$Basics$always(
				A3($author$project$Intervals$useIntervalShortenedOnTheRight, newFrom, interval, newList)),
			function (to) {
				var _v0 = A2($justinmimbs$date$Date$compare, newFrom, to);
				if (_v0 === 2) {
					return A2($author$project$Intervals$useIntervalUnchanged, interval, newList);
				} else {
					return A3($author$project$Intervals$useIntervalShortenedOnTheRight, newFrom, interval, newList);
				}
			},
			function (from) {
				var _v1 = A2($justinmimbs$date$Date$compare, newFrom, from);
				if (_v1 === 2) {
					return A3($author$project$Intervals$useIntervalShortenedOnTheRight, newFrom, interval, newList);
				} else {
					return $author$project$Intervals$skipInterval(newList);
				}
			},
			F2(
				function (from, to) {
					var _v2 = _Utils_Tuple2(
						A2($justinmimbs$date$Date$compare, newFrom, from),
						A2($justinmimbs$date$Date$compare, newFrom, to));
					switch (_v2.a) {
						case 2:
							if (_v2.b === 2) {
								var _v3 = _v2.a;
								var _v4 = _v2.b;
								return A2($author$project$Intervals$useIntervalUnchanged, interval, newList);
							} else {
								return A3($author$project$Intervals$useIntervalShortenedOnTheRight, newFrom, interval, newList);
							}
						case 0:
							var _v5 = _v2.a;
							return $author$project$Intervals$skipInterval(newList);
						default:
							var _v6 = _v2.a;
							return $author$project$Intervals$skipInterval(newList);
					}
				}),
			interval.gy);
	});
var $author$project$Intervals$foldIntervalWithRightBoundedRoot = F3(
	function (newTo, interval, newList) {
		return A5(
			$author$project$Enectiva$DateRange$mapUnboundedRange,
			$elm$core$Basics$always(
				A3($author$project$Intervals$useIntervalShortenedOnTheLeft, newTo, interval, newList)),
			function (to) {
				var _v0 = A2($justinmimbs$date$Date$compare, newTo, to);
				if (!_v0) {
					return A3($author$project$Intervals$useIntervalShortenedOnTheLeft, newTo, interval, newList);
				} else {
					return $author$project$Intervals$skipInterval(newList);
				}
			},
			function (from) {
				var _v1 = A2($justinmimbs$date$Date$compare, newTo, from);
				if (!_v1) {
					return A2($author$project$Intervals$useIntervalUnchanged, interval, newList);
				} else {
					return A3($author$project$Intervals$useIntervalShortenedOnTheLeft, newTo, interval, newList);
				}
			},
			F2(
				function (from, to) {
					var _v2 = _Utils_Tuple2(
						A2($justinmimbs$date$Date$compare, newTo, from),
						A2($justinmimbs$date$Date$compare, newTo, to));
					_v2$3:
					while (true) {
						switch (_v2.a) {
							case 2:
								switch (_v2.b) {
									case 2:
										var _v3 = _v2.a;
										var _v4 = _v2.b;
										return $author$project$Intervals$skipInterval(newList);
									case 1:
										var _v5 = _v2.a;
										var _v6 = _v2.b;
										return $author$project$Intervals$skipInterval(newList);
									default:
										break _v2$3;
								}
							case 0:
								if (!_v2.b) {
									var _v7 = _v2.a;
									var _v8 = _v2.b;
									return A2($author$project$Intervals$useIntervalUnchanged, interval, newList);
								} else {
									break _v2$3;
								}
							default:
								break _v2$3;
						}
					}
					return A3($author$project$Intervals$useIntervalShortenedOnTheLeft, newTo, interval, newList);
				}),
			interval.gy);
	});
var $author$project$Enectiva$DateRange$compareUnboundedRanges = F2(
	function (a, b) {
		var _v0 = _Utils_Tuple2(a.a, b.a);
		if (!_v0.a.$) {
			if (!_v0.b.$) {
				var _v1 = _v0.a.a;
				var _v2 = _v0.b.a;
				return 1;
			} else {
				var _v3 = _v0.a.a;
				return 0;
			}
		} else {
			if (!_v0.b.$) {
				var _v4 = _v0.b.a;
				return 2;
			} else {
				var aa = _v0.a.a;
				var bb = _v0.b.a;
				return A2($justinmimbs$date$Date$compare, aa, bb);
			}
		}
	});
var $author$project$Intervals$sortByRangeStart = F2(
	function (a, b) {
		return A2($author$project$Enectiva$DateRange$compareUnboundedRanges, a.gy, b.gy);
	});
var $turboMaCk$non_empty_list_alias$List$NonEmpty$sortHelper = F2(
	function (f, ne) {
		var _v0 = f(
			$turboMaCk$non_empty_list_alias$List$NonEmpty$toList(ne));
		if (_v0.b) {
			var h = _v0.a;
			var t = _v0.b;
			return _Utils_Tuple2(h, t);
		} else {
			return ne;
		}
	});
var $elm$core$List$sortWith = _List_sortWith;
var $turboMaCk$non_empty_list_alias$List$NonEmpty$sortWith = function (f) {
	return $turboMaCk$non_empty_list_alias$List$NonEmpty$sortHelper(
		$elm$core$List$sortWith(f));
};
var $author$project$Intervals$incorporateNewIntervalHelp = F2(
	function (_new, list) {
		var onlyNewInterval = $turboMaCk$non_empty_list_alias$List$NonEmpty$singleton(_new);
		return A5(
			$author$project$Enectiva$DateRange$mapUnboundedRange,
			$elm$core$Basics$always(onlyNewInterval),
			function (newTo) {
				return A2(
					$turboMaCk$non_empty_list_alias$List$NonEmpty$sortWith,
					$author$project$Intervals$sortByRangeStart,
					A3(
						$turboMaCk$non_empty_list_alias$List$NonEmpty$foldl,
						$author$project$Intervals$foldIntervalWithRightBoundedRoot(newTo),
						onlyNewInterval,
						list));
			},
			function (newFrom) {
				return A2(
					$turboMaCk$non_empty_list_alias$List$NonEmpty$sortWith,
					$author$project$Intervals$sortByRangeStart,
					A3(
						$turboMaCk$non_empty_list_alias$List$NonEmpty$foldl,
						$author$project$Intervals$foldIntervalWithLeftBoundedRoot(newFrom),
						onlyNewInterval,
						list));
			},
			F2(
				function (newFrom, newTo) {
					return A2(
						$turboMaCk$non_empty_list_alias$List$NonEmpty$sortWith,
						$author$project$Intervals$sortByRangeStart,
						A3(
							$turboMaCk$non_empty_list_alias$List$NonEmpty$foldl,
							A2($author$project$Intervals$foldIntervalWithFullyBoundedRoot, newFrom, newTo),
							onlyNewInterval,
							list));
				}),
			_new.gy);
	});
var $author$project$Intervals$incorporateNewInterval = F2(
	function (maybeNew, list) {
		if (maybeNew.$ === 1) {
			return list;
		} else {
			var _new = maybeNew.a;
			return A2($author$project$Intervals$incorporateNewIntervalHelp, _new, list);
		}
	});
var $author$project$ElPriceConfiguration$lastOfMonth = function (date) {
	return A3(
		$justinmimbs$date$Date$fromCalendarDate,
		$justinmimbs$date$Date$year(date),
		$justinmimbs$date$Date$month(date),
		31);
};
var $author$project$ElPriceConfiguration$NoEffect = {$: 0};
var $author$project$ElPriceConfiguration$noEffect = function (model) {
	return _Utils_Tuple2(model, $author$project$ElPriceConfiguration$NoEffect);
};
var $author$project$ElPriceConfiguration$setNewInterval = F2(
	function (model, maybeInterval) {
		return _Utils_update(
			model,
			{al: maybeInterval});
	});
var $author$project$ElPriceConfiguration$ParseFloat = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Enectiva$DateRange$boundedOrFallback = F3(
	function (field, fallback, _v0) {
		var from = _v0.a;
		var to = _v0.b;
		if (!field) {
			if (!from.$) {
				return fallback;
			} else {
				var d = from.a;
				return d;
			}
		} else {
			if (!to.$) {
				return fallback;
			} else {
				var d = to.a;
				return d;
			}
		}
	});
var $author$project$ElPriceConfiguration$newIntervalDate = F4(
	function (fallback, field, fn, interval) {
		var oldDate = A3($author$project$Enectiva$DateRange$boundedOrFallback, field, fallback, interval.gy);
		var newDate = function (_v1) {
			var y = _v1.a;
			var m = _v1.b;
			var d = _v1.c;
			return A3($justinmimbs$date$Date$fromCalendarDate, y, m, d);
		}(
			fn(
				_Utils_Tuple3(
					$justinmimbs$date$Date$year(oldDate),
					$justinmimbs$date$Date$month(oldDate),
					$justinmimbs$date$Date$day(oldDate))));
		if (!field) {
			return $author$project$ElPriceConfiguration$firstOfMonth(newDate);
		} else {
			return $author$project$ElPriceConfiguration$lastOfMonth(newDate);
		}
	});
var $elm$core$String$trim = _String_trim;
var $author$project$Intervals$updateRange = F3(
	function (field, date, interval) {
		var range = function () {
			if (!field) {
				return A2($author$project$Enectiva$DateRange$setBoundedFrom, date, interval.gy);
			} else {
				return A2($author$project$Enectiva$DateRange$setBoundedTo, date, interval.gy);
			}
		}();
		return _Utils_update(
			interval,
			{gy: range});
	});
var $author$project$ElPriceConfiguration$updateNewInterval = F3(
	function (today, msg, interval) {
		switch (msg.$) {
			case 0:
				var field = msg.a;
				var str = msg.b;
				if ($elm$core$String$trim(str) === '') {
					switch (field) {
						case 0:
							return $author$project$ElPriceConfiguration$noEffect(
								_Utils_update(
									interval,
									{bt: $elm$core$Maybe$Nothing}));
						case 1:
							return $author$project$ElPriceConfiguration$noEffect(
								_Utils_update(
									interval,
									{bx: $elm$core$Maybe$Nothing}));
						default:
							return $author$project$ElPriceConfiguration$noEffect(
								_Utils_update(
									interval,
									{by: $elm$core$Maybe$Nothing}));
					}
				} else {
					return _Utils_Tuple2(
						interval,
						A3($author$project$ElPriceConfiguration$ParseFloat, $author$project$Intervals$New, field, str));
				}
			case 3:
				var val = msg.a;
				return $author$project$ElPriceConfiguration$noEffect(
					_Utils_update(
						interval,
						{bJ: val}));
			case 4:
				var val = msg.a;
				return $author$project$ElPriceConfiguration$noEffect(
					_Utils_update(
						interval,
						{bY: val}));
			case 1:
				var field = msg.a;
				var str = msg.b;
				var _v2 = $elm$core$String$toInt(str);
				if (_v2.$ === 1) {
					return $author$project$ElPriceConfiguration$noEffect(interval);
				} else {
					var y = _v2.a;
					return $author$project$ElPriceConfiguration$noEffect(
						A3(
							$author$project$Intervals$updateRange,
							field,
							A4(
								$author$project$ElPriceConfiguration$newIntervalDate,
								today,
								field,
								function (_v3) {
									var m = _v3.b;
									var d = _v3.c;
									return _Utils_Tuple3(y, m, d);
								},
								interval),
							interval));
				}
			default:
				var field = msg.a;
				var month = msg.b;
				return $author$project$ElPriceConfiguration$noEffect(
					A3(
						$author$project$Intervals$updateRange,
						field,
						A4(
							$author$project$ElPriceConfiguration$newIntervalDate,
							today,
							field,
							function (_v4) {
								var y = _v4.a;
								var d = _v4.c;
								return _Utils_Tuple3(y, month, d);
							},
							interval),
						interval));
		}
	});
var $author$project$Enectiva$DateRange$setUnboundedFrom = $author$project$Enectiva$DateRange$setFrom(
	$author$project$Enectiva$DateRange$Infinity(0));
var $author$project$Enectiva$DateRange$setUnboundedTo = $author$project$Enectiva$DateRange$setTo(
	$author$project$Enectiva$DateRange$Infinity(0));
var $author$project$Intervals$updateNewInterval = F4(
	function (fromFillIn, toFillIn, msg, maybeNew) {
		switch (msg.$) {
			case 0:
				return A2(
					$elm$core$Maybe$map,
					function (n) {
						return _Utils_update(
							n,
							{
								gy: $author$project$Enectiva$DateRange$setUnboundedFrom(n.gy)
							});
					},
					maybeNew);
			case 1:
				return A2(
					$elm$core$Maybe$map,
					function (n) {
						return _Utils_update(
							n,
							{
								gy: $author$project$Enectiva$DateRange$setUnboundedTo(n.gy)
							});
					},
					maybeNew);
			default:
				if (!msg.a) {
					var _v1 = msg.a;
					return A2(
						$elm$core$Maybe$map,
						function (n) {
							return _Utils_update(
								n,
								{
									gy: A2($author$project$Enectiva$DateRange$setBoundedFrom, fromFillIn, n.gy)
								});
						},
						maybeNew);
				} else {
					var _v2 = msg.a;
					return A2(
						$elm$core$Maybe$map,
						function (n) {
							return _Utils_update(
								n,
								{
									gy: A2($author$project$Enectiva$DateRange$setBoundedTo, toFillIn, n.gy)
								});
						},
						maybeNew);
				}
		}
	});
var $author$project$ElPriceConfiguration$newIntervalUpdate = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				return $author$project$ElPriceConfiguration$noEffect(
					A2($author$project$ElPriceConfiguration$setNewInterval, model, $elm$core$Maybe$Nothing));
			case 2:
				var m = msg.a;
				return $author$project$ElPriceConfiguration$noEffect(
					A2(
						$author$project$ElPriceConfiguration$setNewInterval,
						model,
						A4(
							$author$project$Intervals$updateNewInterval,
							$author$project$ElPriceConfiguration$firstOfMonth(model.an),
							$author$project$ElPriceConfiguration$lastOfMonth(model.an),
							m,
							model.al)));
			case 0:
				var m = msg.a;
				var _v1 = model.al;
				if (_v1.$ === 1) {
					return $author$project$ElPriceConfiguration$noEffect(model);
				} else {
					var _new = _v1.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$Just,
							$author$project$ElPriceConfiguration$setNewInterval(model)),
						A3($author$project$ElPriceConfiguration$updateNewInterval, model.an, m, _new));
				}
			default:
				var _v2 = A2(
					$author$project$Intervals$ensureUniqueUuids,
					model.cb,
					A2($author$project$Intervals$incorporateNewInterval, model.al, model.f));
				var newSeed = _v2.a;
				var updatedIntervals = _v2.b;
				return $author$project$ElPriceConfiguration$noEffect(
					_Utils_update(
						model,
						{f: updatedIntervals, al: $elm$core$Maybe$Nothing, cb: newSeed}));
		}
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $author$project$Intervals$removeIntervalHelp = F4(
	function (index, head, shoulders, tail) {
		var eatUpPrevious = F2(
			function (prev, curr) {
				return _Utils_update(
					curr,
					{
						gy: A2($author$project$Enectiva$DateRange$setFrom, prev.gy.a, curr.gy)
					});
			});
		var eatUpNext = F2(
			function (curr, next) {
				return _Utils_update(
					curr,
					{
						gy: A2($author$project$Enectiva$DateRange$setTo, next.gy.b, curr.gy)
					});
			});
		if (!tail.b) {
			switch (index) {
				case 0:
					return $turboMaCk$non_empty_list_alias$List$NonEmpty$singleton(
						A2(eatUpPrevious, head, shoulders));
				case 1:
					return $turboMaCk$non_empty_list_alias$List$NonEmpty$singleton(
						A2(eatUpNext, head, shoulders));
				default:
					return A2(
						$turboMaCk$non_empty_list_alias$List$NonEmpty$fromCons,
						head,
						_List_fromArray(
							[shoulders]));
			}
		} else {
			if (!tail.b.b) {
				var t = tail.a;
				switch (index) {
					case 0:
						return A2(
							$turboMaCk$non_empty_list_alias$List$NonEmpty$fromCons,
							A2(eatUpPrevious, head, shoulders),
							_List_fromArray(
								[t]));
					case 1:
						return A2(
							$turboMaCk$non_empty_list_alias$List$NonEmpty$fromCons,
							A2(eatUpNext, head, shoulders),
							_List_fromArray(
								[t]));
					case 2:
						return A2(
							$turboMaCk$non_empty_list_alias$List$NonEmpty$fromCons,
							head,
							_List_fromArray(
								[
									A2(eatUpNext, shoulders, t)
								]));
					default:
						return A2(
							$turboMaCk$non_empty_list_alias$List$NonEmpty$fromCons,
							head,
							A2($elm$core$List$cons, shoulders, tail));
				}
			} else {
				var t = tail.a;
				var ts = tail.b;
				return (!index) ? A2(
					$turboMaCk$non_empty_list_alias$List$NonEmpty$fromCons,
					A2(eatUpPrevious, head, shoulders),
					tail) : A2(
					$turboMaCk$non_empty_list_alias$List$NonEmpty$cons,
					head,
					A4($author$project$Intervals$removeIntervalHelp, index - 1, shoulders, t, ts));
			}
		}
	});
var $author$project$Intervals$removeInterval = F2(
	function (idx, intervals) {
		var head = $turboMaCk$non_empty_list_alias$List$NonEmpty$head(intervals);
		var _v0 = $turboMaCk$non_empty_list_alias$List$NonEmpty$tail(intervals);
		if (!_v0.b) {
			return $turboMaCk$non_empty_list_alias$List$NonEmpty$singleton(
				_Utils_update(
					head,
					{gy: $author$project$Enectiva$DateRange$sinceForeverUntilForever}));
		} else {
			var shoulders = _v0.a;
			var rest = _v0.b;
			return A4($author$project$Intervals$removeIntervalHelp, idx, head, shoulders, rest);
		}
	});
var $author$project$ElPriceConfiguration$Save = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$ElPriceConfiguration$saveEffect = function (model) {
	return _Utils_Tuple2(
		model,
		A2($author$project$ElPriceConfiguration$Save, model.cX, model.f));
};
var $turboMaCk$non_empty_list_alias$List$NonEmpty$indexedMap = F2(
	function (f, _v0) {
		var h = _v0.a;
		var t = _v0.b;
		return _Utils_Tuple2(
			A2(f, 0, h),
			A2(
				$elm$core$List$indexedMap,
				function (i) {
					return f(i + 1);
				},
				t));
	});
var $author$project$Intervals$updateByIndex = F3(
	function (index, fn, list) {
		return A2(
			$turboMaCk$non_empty_list_alias$List$NonEmpty$indexedMap,
			F2(
				function (idx, interval) {
					return _Utils_eq(idx, index) ? fn(interval) : interval;
				}),
			list);
	});
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $author$project$Enectiva$DateRange$dateIsAtOrAfter = F2(
	function (a, b) {
		var _v0 = A2($justinmimbs$date$Date$compare, a, b);
		if (!_v0) {
			return false;
		} else {
			return true;
		}
	});
var $author$project$Enectiva$DateRange$dateIsAtOrAfterSince = F2(
	function (a, range) {
		var _v0 = range.a;
		if (!_v0.$) {
			return true;
		} else {
			var d = _v0.a;
			return A2($author$project$Enectiva$DateRange$dateIsAtOrAfter, a, d);
		}
	});
var $author$project$Enectiva$DateRange$dateIsBeforeOrAt = F2(
	function (a, b) {
		var _v0 = A2($justinmimbs$date$Date$compare, a, b);
		if (_v0 === 2) {
			return false;
		} else {
			return true;
		}
	});
var $author$project$Enectiva$DateRange$dateIsBeforeOrAtSince = F2(
	function (a, range) {
		var _v0 = range.a;
		if (!_v0.$) {
			return false;
		} else {
			var d = _v0.a;
			return A2($author$project$Enectiva$DateRange$dateIsBeforeOrAt, a, d);
		}
	});
var $author$project$Intervals$updateValidFrom = F4(
	function (date, index, previous, intervals) {
		if (!intervals.b) {
			return $turboMaCk$non_empty_list_alias$List$NonEmpty$singleton(previous);
		} else {
			var current = intervals.a;
			var rest = intervals.b;
			if (index === 1) {
				var unchanged = function (_v4) {
					return A2(
						$turboMaCk$non_empty_list_alias$List$NonEmpty$fromCons,
						previous,
						A2($elm$core$List$cons, current, rest));
				};
				if (A2($author$project$Enectiva$DateRange$dateIsBeforeOrAtSince, date, previous.gy)) {
					return unchanged(0);
				} else {
					var shift = function (_v3) {
						return A2(
							$turboMaCk$non_empty_list_alias$List$NonEmpty$fromCons,
							_Utils_update(
								previous,
								{
									gy: A2(
										$author$project$Enectiva$DateRange$setBoundedTo,
										A3($justinmimbs$date$Date$add, 3, -1, date),
										previous.gy)
								}),
							A2(
								$elm$core$List$cons,
								_Utils_update(
									current,
									{
										gy: A2($author$project$Enectiva$DateRange$setBoundedFrom, date, current.gy)
									}),
								rest));
					};
					if (!rest.b) {
						return shift(0);
					} else {
						var next = rest.a;
						return A2($author$project$Enectiva$DateRange$dateIsAtOrAfterSince, date, next.gy) ? unchanged(0) : shift(0);
					}
				}
			} else {
				return A2(
					$turboMaCk$non_empty_list_alias$List$NonEmpty$cons,
					previous,
					A4($author$project$Intervals$updateValidFrom, date, index - 1, current, rest));
			}
		}
	});
var $author$project$Enectiva$DateRange$dateIsAtOrAfterUntil = F2(
	function (a, range) {
		var _v0 = range.b;
		if (!_v0.$) {
			return false;
		} else {
			var d = _v0.a;
			return A2($author$project$Enectiva$DateRange$dateIsAtOrAfter, a, d);
		}
	});
var $author$project$Enectiva$DateRange$dateIsBeforeOrAtUntil = F2(
	function (a, range) {
		var _v0 = range.b;
		if (!_v0.$) {
			return true;
		} else {
			var d = _v0.a;
			return A2($author$project$Enectiva$DateRange$dateIsBeforeOrAt, a, d);
		}
	});
var $author$project$Intervals$updateValidTo = F4(
	function (date, index, previous, intervals) {
		if (!intervals.b) {
			return $turboMaCk$non_empty_list_alias$List$NonEmpty$singleton(previous);
		} else {
			var current = intervals.a;
			var rest = intervals.b;
			var unchanged = function (_v3) {
				return A2(
					$turboMaCk$non_empty_list_alias$List$NonEmpty$fromCons,
					previous,
					A2($elm$core$List$cons, current, rest));
			};
			var recurse = function (_v2) {
				return A2(
					$turboMaCk$non_empty_list_alias$List$NonEmpty$cons,
					previous,
					A4($author$project$Intervals$updateValidTo, date, index - 1, current, rest));
			};
			switch (index) {
				case 0:
					return A2($author$project$Enectiva$DateRange$dateIsAtOrAfterUntil, date, current.gy) ? unchanged(0) : A2(
						$turboMaCk$non_empty_list_alias$List$NonEmpty$fromCons,
						_Utils_update(
							previous,
							{
								gy: A2($author$project$Enectiva$DateRange$setBoundedTo, date, previous.gy)
							}),
						A2(
							$elm$core$List$cons,
							_Utils_update(
								current,
								{
									gy: A2(
										$author$project$Enectiva$DateRange$setBoundedFrom,
										A3($justinmimbs$date$Date$add, 3, 1, date),
										current.gy)
								}),
							rest));
				case 1:
					return A2($author$project$Enectiva$DateRange$dateIsBeforeOrAtUntil, date, previous.gy) ? unchanged(0) : recurse(0);
				default:
					return recurse(0);
			}
		}
	});
var $author$project$Intervals$updateDateByIndex = F4(
	function (index, field, date, intervals) {
		if (!field) {
			return A4(
				$author$project$Intervals$updateValidFrom,
				date,
				index,
				$turboMaCk$non_empty_list_alias$List$NonEmpty$head(intervals),
				$turboMaCk$non_empty_list_alias$List$NonEmpty$tail(intervals));
		} else {
			return A4(
				$author$project$Intervals$updateValidTo,
				date,
				index,
				$turboMaCk$non_empty_list_alias$List$NonEmpty$head(intervals),
				$turboMaCk$non_empty_list_alias$List$NonEmpty$tail(intervals));
		}
	});
var $author$project$ElPriceConfiguration$updateExistingInterval = F3(
	function (index, msg, model) {
		switch (msg.$) {
			case 0:
				var field = msg.a;
				var str = msg.b;
				if ($elm$core$String$trim(str) === '') {
					switch (field) {
						case 0:
							return $author$project$ElPriceConfiguration$noEffect(
								_Utils_update(
									model,
									{
										f: A3(
											$author$project$Intervals$updateByIndex,
											index,
											function (i) {
												return _Utils_update(
													i,
													{bt: $elm$core$Maybe$Nothing});
											},
											model.f)
									}));
						case 1:
							return $author$project$ElPriceConfiguration$noEffect(
								_Utils_update(
									model,
									{
										f: A3(
											$author$project$Intervals$updateByIndex,
											index,
											function (i) {
												return _Utils_update(
													i,
													{bx: $elm$core$Maybe$Nothing});
											},
											model.f)
									}));
						default:
							return $author$project$ElPriceConfiguration$noEffect(
								_Utils_update(
									model,
									{
										f: A3(
											$author$project$Intervals$updateByIndex,
											index,
											function (i) {
												return _Utils_update(
													i,
													{by: $elm$core$Maybe$Nothing});
											},
											model.f)
									}));
					}
				} else {
					return _Utils_Tuple2(
						model,
						A3(
							$author$project$ElPriceConfiguration$ParseFloat,
							$author$project$Intervals$Existing(index),
							field,
							str));
				}
			case 3:
				var val = msg.a;
				return $author$project$ElPriceConfiguration$noEffect(
					_Utils_update(
						model,
						{
							f: A3(
								$author$project$Intervals$updateByIndex,
								index,
								function (i) {
									return _Utils_update(
										i,
										{bJ: val});
								},
								model.f)
						}));
			case 4:
				var val = msg.a;
				return $author$project$ElPriceConfiguration$noEffect(
					_Utils_update(
						model,
						{
							f: A3(
								$author$project$Intervals$updateByIndex,
								index,
								function (i) {
									return _Utils_update(
										i,
										{bY: val});
								},
								model.f)
						}));
			case 1:
				var field = msg.a;
				var str = msg.b;
				var _v2 = $elm$core$String$toInt(str);
				if (_v2.$ === 1) {
					return $author$project$ElPriceConfiguration$noEffect(model);
				} else {
					var y = _v2.a;
					var date = A2(
						$elm$core$Maybe$withDefault,
						model.an,
						A2(
							$elm$core$Maybe$map,
							A3(
								$author$project$ElPriceConfiguration$newIntervalDate,
								model.an,
								field,
								function (_v3) {
									var m = _v3.b;
									var d = _v3.c;
									return _Utils_Tuple3(y, m, d);
								}),
							A2(
								$elm_community$list_extra$List$Extra$getAt,
								index,
								$turboMaCk$non_empty_list_alias$List$NonEmpty$toList(model.f))));
					return $author$project$ElPriceConfiguration$noEffect(
						_Utils_update(
							model,
							{
								f: A4($author$project$Intervals$updateDateByIndex, index, field, date, model.f)
							}));
				}
			default:
				var field = msg.a;
				var month = msg.b;
				var date = A2(
					$elm$core$Maybe$withDefault,
					model.an,
					A2(
						$elm$core$Maybe$map,
						A3(
							$author$project$ElPriceConfiguration$newIntervalDate,
							model.an,
							field,
							function (_v4) {
								var y = _v4.a;
								var m = _v4.b;
								var d = _v4.c;
								return _Utils_Tuple3(y, month, d);
							}),
						A2(
							$elm_community$list_extra$List$Extra$getAt,
							index,
							$turboMaCk$non_empty_list_alias$List$NonEmpty$toList(model.f))));
				return $author$project$ElPriceConfiguration$noEffect(
					_Utils_update(
						model,
						{
							f: A4($author$project$Intervals$updateDateByIndex, index, field, date, model.f)
						}));
		}
	});
var $author$project$ElPriceConfiguration$updateEffect = F2(
	function (msg, model) {
		switch (msg.$) {
			case 4:
				var idx = msg.a;
				return $author$project$ElPriceConfiguration$noEffect(
					_Utils_update(
						model,
						{
							f: A2($author$project$Intervals$removeInterval, idx, model.f)
						}));
			case 0:
				var idx = msg.a;
				var m = msg.b;
				return A3($author$project$ElPriceConfiguration$updateExistingInterval, idx, m, model);
			case 2:
				if (!msg.a.$) {
					switch (msg.b) {
						case 0:
							var idx = msg.a.a;
							var _v1 = msg.b;
							var val = msg.c;
							return $author$project$ElPriceConfiguration$noEffect(
								_Utils_update(
									model,
									{
										f: A3(
											$author$project$Intervals$updateByIndex,
											idx,
											function (i) {
												return _Utils_update(
													i,
													{
														bt: $elm$core$Maybe$Just(val)
													});
											},
											model.f)
									}));
						case 1:
							var idx = msg.a.a;
							var _v5 = msg.b;
							var val = msg.c;
							return $author$project$ElPriceConfiguration$noEffect(
								_Utils_update(
									model,
									{
										f: A3(
											$author$project$Intervals$updateByIndex,
											idx,
											function (i) {
												return _Utils_update(
													i,
													{
														bx: $elm$core$Maybe$Just(val)
													});
											},
											model.f)
									}));
						default:
							var idx = msg.a.a;
							var _v9 = msg.b;
							var val = msg.c;
							return $author$project$ElPriceConfiguration$noEffect(
								_Utils_update(
									model,
									{
										f: A3(
											$author$project$Intervals$updateByIndex,
											idx,
											function (i) {
												return _Utils_update(
													i,
													{
														by: $elm$core$Maybe$Just(val)
													});
											},
											model.f)
									}));
					}
				} else {
					switch (msg.b) {
						case 0:
							var _v2 = msg.a;
							var _v3 = msg.b;
							var val = msg.c;
							var _v4 = model.al;
							if (_v4.$ === 1) {
								return $author$project$ElPriceConfiguration$noEffect(model);
							} else {
								var _new = _v4.a;
								return $author$project$ElPriceConfiguration$noEffect(
									A2(
										$author$project$ElPriceConfiguration$setNewInterval,
										model,
										$elm$core$Maybe$Just(
											_Utils_update(
												_new,
												{
													bt: $elm$core$Maybe$Just(val)
												}))));
							}
						case 1:
							var _v6 = msg.a;
							var _v7 = msg.b;
							var val = msg.c;
							var _v8 = model.al;
							if (_v8.$ === 1) {
								return $author$project$ElPriceConfiguration$noEffect(model);
							} else {
								var _new = _v8.a;
								return $author$project$ElPriceConfiguration$noEffect(
									A2(
										$author$project$ElPriceConfiguration$setNewInterval,
										model,
										$elm$core$Maybe$Just(
											_Utils_update(
												_new,
												{
													bx: $elm$core$Maybe$Just(val)
												}))));
							}
						default:
							var _v10 = msg.a;
							var _v11 = msg.b;
							var val = msg.c;
							var _v12 = model.al;
							if (_v12.$ === 1) {
								return $author$project$ElPriceConfiguration$noEffect(model);
							} else {
								var _new = _v12.a;
								return $author$project$ElPriceConfiguration$noEffect(
									A2(
										$author$project$ElPriceConfiguration$setNewInterval,
										model,
										$elm$core$Maybe$Just(
											_Utils_update(
												_new,
												{
													by: $elm$core$Maybe$Just(val)
												}))));
							}
					}
				}
			case 3:
				var _v13 = A2($NoRedInk$elm_random_pcg_extended$Random$Pcg$Extended$step, $NoRedInk$elm_uuid$Prng$Uuid$generator, model.cb);
				var uuid = _v13.a;
				var seed = _v13.b;
				return $author$project$ElPriceConfiguration$noEffect(
					_Utils_update(
						model,
						{
							al: $elm$core$Maybe$Just(
								A3(
									$author$project$ElPriceConfiguration$emptyInterval,
									$author$project$ElPriceConfiguration$firstOfMonth(model.an),
									uuid,
									$turboMaCk$non_empty_list_alias$List$NonEmpty$last(model.f))),
							cb: seed
						}));
			case 1:
				var m = msg.a;
				return A2($author$project$ElPriceConfiguration$newIntervalUpdate, m, model);
			case 5:
				return $author$project$ElPriceConfiguration$noEffect(model);
			case 6:
				return _Utils_Tuple2(model, $author$project$ElPriceConfiguration$CloseDialog);
			case 7:
				return $author$project$ElPriceConfiguration$saveEffect(
					_Utils_update(
						model,
						{bz: $author$project$AreaForm$Inner$Loading}));
			default:
				if (!msg.a.$) {
					var cv = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bz: $author$project$AreaForm$Inner$Success(0)
							}),
						$author$project$ElPriceConfiguration$UpdateCurrentIntervalAndCloseDialog(cv));
				} else {
					var err = msg.a.a;
					return $author$project$ElPriceConfiguration$noEffect(
						_Utils_update(
							model,
							{
								bz: $author$project$AreaForm$Inner$Failure(err)
							}));
				}
		}
	});
var $author$project$ElPriceConfiguration$update = F2(
	function (msg, model) {
		return $author$project$ElPriceConfiguration$effectToCmd(
			A2($author$project$ElPriceConfiguration$updateEffect, msg, model));
	});
var $author$project$ElPriceConfiguration$FormSubmitted = {$: 7};
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $author$project$ElPriceConfiguration$CloseDialogButtonClicked = {$: 6};
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Enectiva$I18n$keyToKeyPath = $elm$core$String$split('.');
var $author$project$Enectiva$I18n$tHelp = F2(
	function (_v0, keyPath) {
		var translations = _v0.b;
		return A2(
			$elm$json$Json$Decode$decodeValue,
			A2($elm$json$Json$Decode$at, keyPath, $elm$json$Json$Decode$string),
			translations);
	});
var $author$project$Enectiva$I18n$t = F2(
	function (translations, key) {
		return A2(
			$elm$core$Result$withDefault,
			key,
			A2(
				$author$project$Enectiva$I18n$tHelp,
				translations,
				$author$project$Enectiva$I18n$keyToKeyPath(key)));
	});
var $author$project$View$tt = F2(
	function (translations, path) {
		return $elm$html$Html$text(
			A2($author$project$Enectiva$I18n$t, translations, path));
	});
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$ElPriceConfiguration$closeButton = function (translations) {
	return A2(
		$elm$html$Html$button,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$type_('button'),
				$elm$html$Html$Events$onClick($author$project$ElPriceConfiguration$CloseDialogButtonClicked),
				$elm$html$Html$Attributes$class('subdued-button')
			]),
		_List_fromArray(
			[
				A2($author$project$View$tt, translations, 'datepicker.close')
			]));
};
var $author$project$Spinner$container = $elm$html$Html$Attributes$class('relative');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$Attributes$colspan = function (n) {
	return A2(
		_VirtualDom_attribute,
		'colspan',
		$elm$core$String$fromInt(n));
};
var $author$project$ElPriceConfiguration$ExistingIntervalMsg = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$ElPriceConfiguration$RemoveIntervalButtonClicked = function (a) {
	return {$: 4, a: a};
};
var $elm$html$Html$td = _VirtualDom_node('td');
var $author$project$DateRangeView$dashCell = function (attrs) {
	return A2(
		$elm$html$Html$td,
		A2(
			$elm$core$List$cons,
			$elm$html$Html$Attributes$class('p-0 text-center text-grey-600'),
			attrs),
		_List_fromArray(
			[
				$elm$html$Html$text(' – ')
			]));
};
var $author$project$Enectiva$DateRange$ValidFrom = 0;
var $author$project$ElPriceConfiguration$MonthSelected = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$ElPriceConfiguration$YearEntered = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$html$Html$input = _VirtualDom_node('input');
var $author$project$Tc$MonthsValues$monthToMonthIndex = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $author$project$Tc$MonthsValues$localizedMonth = F2(
	function (translations, month) {
		var idx = $elm$core$String$fromInt(
			$author$project$Tc$MonthsValues$monthToMonthIndex(month));
		return A2($author$project$Enectiva$I18n$t, translations, 'date.month_names.' + idx);
	});
var $author$project$Tc$MonthsValues$monthByIndex = _List_fromArray(
	[
		_Utils_Tuple2(1, 0),
		_Utils_Tuple2(2, 1),
		_Utils_Tuple2(3, 2),
		_Utils_Tuple2(4, 3),
		_Utils_Tuple2(5, 4),
		_Utils_Tuple2(6, 5),
		_Utils_Tuple2(7, 6),
		_Utils_Tuple2(8, 7),
		_Utils_Tuple2(9, 8),
		_Utils_Tuple2(10, 9),
		_Utils_Tuple2(11, 10),
		_Utils_Tuple2(12, 11)
	]);
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $author$project$View$onChange = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue));
};
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$ElPriceConfiguration$dateInputs = F3(
	function (translations, field, date) {
		var selectedMonth = $justinmimbs$date$Date$month(date);
		var alignmentClass = function () {
			if (!field) {
				return 'flex-end';
			} else {
				return 'flex-start';
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(alignmentClass),
					$elm$html$Html$Attributes$class('flex flex-row')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$select,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('box-border m-0 border border-solid border-grey-500 rounded-sm px-2 py-1 bg-white'),
							$author$project$View$onChange(
							function (s) {
								var index = A2(
									$elm$core$Maybe$withDefault,
									1,
									$elm$core$String$toInt(s));
								return A2(
									$author$project$ElPriceConfiguration$MonthSelected,
									field,
									A2(
										$elm$core$Maybe$withDefault,
										0,
										$elm$core$List$head(
											A2(
												$elm$core$List$filterMap,
												function (_v0) {
													var i = _v0.a;
													var m = _v0.b;
													return _Utils_eq(i, index) ? $elm$core$Maybe$Just(m) : $elm$core$Maybe$Nothing;
												},
												$author$project$Tc$MonthsValues$monthByIndex))));
							})
						]),
					A2(
						$elm$core$List$map,
						function (_v1) {
							var i = _v1.a;
							var m = _v1.b;
							return A2(
								$elm$html$Html$option,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(
										$elm$core$String$fromInt(i)),
										$elm$html$Html$Attributes$selected(
										_Utils_eq(m, selectedMonth))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($author$project$Tc$MonthsValues$localizedMonth, translations, m))
									]));
						},
						$author$project$Tc$MonthsValues$monthByIndex)),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$author$project$View$onChange(
							$author$project$ElPriceConfiguration$YearEntered(field)),
							$elm$html$Html$Attributes$class('w-16 box-border m-0 border border-solid border-grey-500 rounded-sm px-2 py-1 text-right'),
							$elm$html$Html$Attributes$value(
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date)))
						]),
					_List_Nil)
				]));
	});
var $author$project$Enectiva$DateRange$mapUnboundedFrom = F3(
	function (unbounded, bounded, range) {
		var _v0 = range.a;
		if (!_v0.$) {
			var _v1 = _v0.a;
			return unbounded(0);
		} else {
			var to = _v0.a;
			return bounded(to);
		}
	});
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $author$project$ElPriceConfiguration$editFromCell = F2(
	function (translations, range) {
		return A2(
			$elm$html$Html$td,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-right py-1 px-2')
				]),
			$elm$core$List$singleton(
				A3(
					$author$project$Enectiva$DateRange$mapUnboundedFrom,
					$elm$core$Basics$always(
						$elm$html$Html$text('∞')),
					function (date) {
						return A3($author$project$ElPriceConfiguration$dateInputs, translations, 0, date);
					},
					range)));
	});
var $author$project$Enectiva$DateRange$ValidTo = 1;
var $author$project$Enectiva$DateRange$mapUnboundedTo = F3(
	function (unbounded, bounded, range) {
		var _v0 = range.b;
		if (!_v0.$) {
			var _v1 = _v0.a;
			return unbounded(0);
		} else {
			var to = _v0.a;
			return bounded(to);
		}
	});
var $author$project$ElPriceConfiguration$editToCell = F2(
	function (translations, range) {
		return A2(
			$elm$html$Html$td,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('py-1 px-2')
				]),
			$elm$core$List$singleton(
				A3(
					$author$project$Enectiva$DateRange$mapUnboundedTo,
					$elm$core$Basics$always(
						$elm$html$Html$text('∞')),
					function (date) {
						return A3($author$project$ElPriceConfiguration$dateInputs, translations, 1, date);
					},
					range)));
	});
var $author$project$ElPriceConfiguration$DistributionRatePicked = function (a) {
	return {$: 3, a: a};
};
var $author$project$ElPriceConfiguration$distributionRateOption = F2(
	function (current, value) {
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(value),
					$elm$html$Html$Attributes$selected(
					_Utils_eq(
						current,
						$elm$core$Maybe$Just(value)))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(value)
				]));
	});
var $author$project$ElPriceConfiguration$distributionRateInput = F3(
	function (translations, possibleValues, interval) {
		var emptyValue = '---';
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-row justify-center')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$select,
					_List_fromArray(
						[
							$author$project$View$onChange(
							function (s) {
								return _Utils_eq(s, emptyValue) ? $author$project$ElPriceConfiguration$DistributionRatePicked($elm$core$Maybe$Nothing) : $author$project$ElPriceConfiguration$DistributionRatePicked(
									$elm$core$Maybe$Just(s));
							}),
							$elm$html$Html$Attributes$class('textfield w-32 text-right bg-white border border-solid border-grey-500 rounded-sm px-2 py-1 m-0')
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$elm$html$Html$option,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(emptyValue),
									$elm$html$Html$Attributes$selected(
									_Utils_eq(interval.bJ, $elm$core$Maybe$Nothing))
								]),
							_List_fromArray(
								[
									A2($author$project$View$tt, translations, 'metering_point.el_price_configuration.field_not_set')
								])),
						A2(
							$elm$core$List$map,
							$author$project$ElPriceConfiguration$distributionRateOption(interval.bJ),
							possibleValues)))
				]));
	});
var $author$project$ElPriceConfiguration$FloatValueEntered = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Enectiva$I18n$AsFloat = function (a) {
	return {$: 1, a: a};
};
var $author$project$Enectiva$I18n$escapeDelimiter = function (delimiter) {
	if (delimiter === '.') {
		return '\\.';
	} else {
		return delimiter;
	}
};
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm_community$list_extra$List$Extra$greedyGroupsOfWithStep = F3(
	function (size, step, list) {
		if ((size <= 0) || (step <= 0)) {
			return _List_Nil;
		} else {
			var go = F2(
				function (xs, acc) {
					go:
					while (true) {
						if ($elm$core$List$isEmpty(xs)) {
							return $elm$core$List$reverse(acc);
						} else {
							var $temp$xs = A2($elm$core$List$drop, step, xs),
								$temp$acc = A2(
								$elm$core$List$cons,
								A2($elm$core$List$take, size, xs),
								acc);
							xs = $temp$xs;
							acc = $temp$acc;
							continue go;
						}
					}
				});
			return A2(go, list, _List_Nil);
		}
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOf = F2(
	function (size, xs) {
		return A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, size, xs);
	});
var $elm$core$String$reverse = _String_reverse;
var $elm$core$Basics$truncate = _Basics_truncate;
var $author$project$Enectiva$I18n$formatIntegerPartOfNumber = F2(
	function (delimiter, number) {
		var escapedDelimiter = $author$project$Enectiva$I18n$escapeDelimiter(delimiter);
		return A3(
			$elm$regex$Regex$replace,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$regex$Regex$never,
				$elm$regex$Regex$fromString('^' + (escapedDelimiter + ('?(.+?)' + (escapedDelimiter + '?$'))))),
			function (_v0) {
				var submatches = _v0.gU;
				return A2(
					$elm$core$Maybe$withDefault,
					'',
					A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Maybe$Nothing,
						$elm$core$List$head(submatches)));
			},
			A3(
				$elm$core$List$foldl,
				F2(
					function (group, acc) {
						var segment = $elm$core$String$fromList(
							$elm$core$List$reverse(group));
						return _Utils_ap(
							segment,
							_Utils_ap(delimiter, acc));
					}),
				'',
				A2(
					$elm_community$list_extra$List$Extra$greedyGroupsOf,
					3,
					$elm$core$String$toList(
						$elm$core$String$reverse(
							$elm$core$String$fromInt(
								$elm$core$Basics$abs(number) | 0))))));
	});
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$Basics$pow = _Basics_pow;
var $author$project$Enectiva$I18n$formatNumberWithPrecision = F3(
	function (translations, precision, number) {
		var sign = (number < 0) ? '-' : '';
		var delimiter = A2($author$project$Enectiva$I18n$t, translations, 'number.format.delimiter');
		if (!precision.$) {
			return _Utils_ap(
				sign,
				A2($author$project$Enectiva$I18n$formatIntegerPartOfNumber, delimiter, number));
		} else {
			var decimalPlaces = precision.a;
			var shift = A2($elm$core$Basics$pow, 10, decimalPlaces);
			var shiftedNumber = $elm$core$Basics$round(
				$elm$core$Basics$abs(number * shift));
			var decimalPart = A3(
				$elm$core$String$padRight,
				decimalPlaces,
				'0',
				A3(
					$elm$core$String$padLeft,
					decimalPlaces,
					'0',
					$elm$core$String$fromInt(
						function (n) {
							return A2($elm$core$Basics$modBy, shift, n);
						}(shiftedNumber))));
			return _Utils_ap(
				sign,
				_Utils_ap(
					A2($author$project$Enectiva$I18n$formatIntegerPartOfNumber, delimiter, (shiftedNumber / shift) | 0),
					_Utils_ap(
						A2($author$project$Enectiva$I18n$t, translations, 'number.format.separator'),
						decimalPart)));
		}
	});
var $author$project$Enectiva$I18n$formatNumber = function (translations) {
	return A2(
		$author$project$Enectiva$I18n$formatNumberWithPrecision,
		translations,
		$author$project$Enectiva$I18n$AsFloat(2));
};
var $author$project$ElPriceConfiguration$maybeFormatNumber = F2(
	function (translations, v) {
		if (v.$ === 1) {
			return '';
		} else {
			var n = v.a;
			return A2($author$project$Enectiva$I18n$formatNumber, translations, n);
		}
	});
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $author$project$ElPriceConfiguration$mainBreakerRatedCurrentInput = F2(
	function (translations, interval) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-row justify-end')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$placeholder(
							A2($author$project$Enectiva$I18n$t, translations, 'metering_point.el_price_configuration.field_not_set')),
							$elm$html$Html$Attributes$value(
							A2($author$project$ElPriceConfiguration$maybeFormatNumber, translations, interval.bt)),
							$author$project$View$onChange(
							$author$project$ElPriceConfiguration$FloatValueEntered(0)),
							$elm$html$Html$Attributes$class('w-32 text-right border border-solid border-grey-500 rounded-sm px-2 py-1 m-0')
						]),
					_List_Nil)
				]));
	});
var $author$project$ElPriceConfiguration$PhaseCountPicked = function (a) {
	return {$: 4, a: a};
};
var $author$project$ElPriceConfiguration$phaseCountOption = F2(
	function (current, value) {
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(
					$elm$core$String$fromInt(value)),
					$elm$html$Html$Attributes$selected(
					_Utils_eq(
						current,
						$elm$core$Maybe$Just(value)))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$elm$core$String$fromInt(value))
				]));
	});
var $author$project$ElPriceConfiguration$numberOfMainBreakerPhasesInput = F3(
	function (translations, possibleValues, interval) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-row justify-center')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$select,
					_List_fromArray(
						[
							$author$project$View$onChange(
							A2($elm$core$Basics$composeR, $elm$core$String$toInt, $author$project$ElPriceConfiguration$PhaseCountPicked)),
							$elm$html$Html$Attributes$class('textfield w-32 text-right bg-white border border-solid border-grey-500 rounded-sm px-2 py-1 m-0')
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$elm$html$Html$option,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(''),
									$elm$html$Html$Attributes$selected(
									_Utils_eq(interval.bY, $elm$core$Maybe$Nothing))
								]),
							_List_fromArray(
								[
									A2($author$project$View$tt, translations, 'metering_point.el_price_configuration.field_not_set')
								])),
						A2(
							$elm$core$List$map,
							$author$project$ElPriceConfiguration$phaseCountOption(interval.bY),
							possibleValues)))
				]));
	});
var $author$project$ElPriceConfiguration$reservedCapacityInput = F2(
	function (translations, interval) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-row justify-end')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$placeholder(
							A2($author$project$Enectiva$I18n$t, translations, 'metering_point.el_price_configuration.field_not_set')),
							$elm$html$Html$Attributes$value(
							A2($author$project$ElPriceConfiguration$maybeFormatNumber, translations, interval.bx)),
							$author$project$View$onChange(
							$author$project$ElPriceConfiguration$FloatValueEntered(1)),
							$elm$html$Html$Attributes$class('w-32 text-right border border-solid border-grey-500 rounded-sm px-2 py-1 m-0')
						]),
					_List_Nil)
				]));
	});
var $author$project$ElPriceConfiguration$reservedWattageInput = F2(
	function (translations, interval) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-row justify-end')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('text'),
							$elm$html$Html$Attributes$placeholder(
							A2($author$project$Enectiva$I18n$t, translations, 'metering_point.el_price_configuration.field_not_set')),
							$elm$html$Html$Attributes$value(
							A2($author$project$ElPriceConfiguration$maybeFormatNumber, translations, interval.by)),
							$author$project$View$onChange(
							$author$project$ElPriceConfiguration$FloatValueEntered(2)),
							$elm$html$Html$Attributes$class('w-32 text-right border border-solid border-grey-500 rounded-sm px-2 py-1 m-0')
						]),
					_List_Nil)
				]));
	});
var $author$project$ElPriceConfiguration$fieldInputCells = F2(
	function (_v0, interval) {
		var translations = _v0.au;
		var phaseCounts = _v0.b$;
		var distributionRates = _v0.bK;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('py-1 px-2')
					]),
				_List_fromArray(
					[
						A2($author$project$ElPriceConfiguration$mainBreakerRatedCurrentInput, translations, interval)
					])),
				A2(
				$elm$html$Html$td,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('py-1 px-2')
					]),
				_List_fromArray(
					[
						A3($author$project$ElPriceConfiguration$numberOfMainBreakerPhasesInput, translations, phaseCounts, interval)
					])),
				A2(
				$elm$html$Html$td,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('py-1 px-2')
					]),
				_List_fromArray(
					[
						A3($author$project$ElPriceConfiguration$distributionRateInput, translations, distributionRates, interval)
					])),
				A2(
				$elm$html$Html$td,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('py-1 px-2')
					]),
				_List_fromArray(
					[
						A2($author$project$ElPriceConfiguration$reservedCapacityInput, translations, interval)
					])),
				A2(
				$elm$html$Html$td,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('py-1 px-2')
					]),
				_List_fromArray(
					[
						A2($author$project$ElPriceConfiguration$reservedWattageInput, translations, interval)
					]))
			]);
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $author$project$SvgIcons$trash = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$class('area-icon delete-icon'),
			$elm$svg$Svg$Attributes$x('0px'),
			$elm$svg$Svg$Attributes$y('0px'),
			$elm$svg$Svg$Attributes$width('17.98px'),
			$elm$svg$Svg$Attributes$height('21px'),
			$elm$svg$Svg$Attributes$viewBox('0 0 17.98 21'),
			$elm$svg$Svg$Attributes$fill('currentColor')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M6.622,0.5h4.532c0.49,0,0.889,0.398,0.889,0.889v0.89h4.444c0.491,0,0.89,0.397,0.89,0.889v0.889 c0,0.491-0.396,0.889-0.89,0.889H1.289c-0.491,0-0.89-0.397-0.89-0.889v-0.89c0-0.491,0.396-0.889,0.89-0.889h4.444v-0.89 C5.734,0.898,6.131,0.5,6.622,0.5z M2.11,18.029C2.213,19.423,3.409,20.5,4.806,20.5h8.164c1.396,0,2.594-1.077,2.695-2.471V6.721 H2.11V18.029z')
				]),
			_List_Nil)
		]));
var $author$project$Intervals$removeButton = F2(
	function (translations, msg) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$type_('button'),
					$elm$html$Html$Events$onClick(msg),
					$elm$html$Html$Attributes$title(
					A2($author$project$Enectiva$I18n$t, translations, 'remove')),
					$elm$html$Html$Attributes$class('delete-icon bg-transparent border-none')
				]),
			_List_fromArray(
				[$author$project$SvgIcons$trash]));
	});
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $author$project$Enectiva$View$viewIf = F2(
	function (view, condition) {
		return condition ? view(0) : $elm$html$Html$text('');
	});
var $author$project$ElPriceConfiguration$intervalRow = F5(
	function (model, removable, onRemoveClicked, tagger, interval) {
		var translations = model.au;
		return A2(
			$elm$html$Html$tr,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('')
				]),
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$map,
						tagger,
						A2($author$project$ElPriceConfiguration$editFromCell, translations, interval.gy)),
						$author$project$DateRangeView$dashCell(_List_Nil),
						A2(
						$elm$html$Html$map,
						tagger,
						A2($author$project$ElPriceConfiguration$editToCell, translations, interval.gy))
					]),
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$elm$html$Html$map(tagger),
						A2($author$project$ElPriceConfiguration$fieldInputCells, model, interval)),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('py-1 pr-0 pl-4 w-8')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Enectiva$View$viewIf,
									function (_v0) {
										return A2($author$project$Intervals$removeButton, translations, onRemoveClicked);
									},
									removable)
								]))
						]))));
	});
var $turboMaCk$non_empty_list_alias$List$NonEmpty$length = function (_v0) {
	var t = _v0.b;
	return $elm$core$List$length(t) + 1;
};
var $author$project$ElPriceConfiguration$existingIntervalRows = function (model) {
	var translations = model.au;
	var intervals = model.f;
	var removable = $turboMaCk$non_empty_list_alias$List$NonEmpty$length(intervals) > 1;
	return A2(
		$elm$core$List$indexedMap,
		F2(
			function (idx, interval) {
				return A5(
					$author$project$ElPriceConfiguration$intervalRow,
					model,
					removable,
					$author$project$ElPriceConfiguration$RemoveIntervalButtonClicked(idx),
					$author$project$ElPriceConfiguration$ExistingIntervalMsg(idx),
					interval);
			}),
		$turboMaCk$non_empty_list_alias$List$NonEmpty$toList(intervals));
};
var $author$project$View$nbsp = $elm$core$String$fromChar(
	$elm$core$Char$fromCode(160));
var $author$project$ElPriceConfiguration$AddIntervalButtonClicked = {$: 3};
var $author$project$ElPriceConfiguration$CancelButtonClicked = {$: 1};
var $author$project$ElPriceConfiguration$IntervalMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$ElPriceConfiguration$NewIntervalMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$ElPriceConfiguration$SaveIntervalButtonClicked = {$: 3};
var $author$project$SvgIcons$plus = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$viewBox('0 0 4.2333 4.2333')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M.62.62c-.8268.8267-.8266 2.1668 0 2.9934.8265.8265 2.1666.8267 2.9934 0 .8267-.8268.8265-2.1669 0-2.9934C2.7868-.2066 1.4468-.207.62.62ZM3.2393 1.852a.2645.2645 0 1 1-.0002.529h-.8579v.858a.2638.2638 0 0 1-.2646.2647.2643.2643 0 0 1-.2645-.2645v-.858h-.858a.2646.2646 0 1 1 0-.5292h.858V.994a.2645.2645 0 1 1 .529 0l.0001.858')
				]),
			_List_Nil)
		]));
var $author$project$Intervals$addNewIntervalButtonRows = F3(
	function (colspan, txt, msg) {
		return $elm$core$List$singleton(
			A2(
				$elm$html$Html$tr,
				_List_Nil,
				$elm$core$List$singleton(
					A2(
						$elm$html$Html$td,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$colspan(colspan),
								$elm$html$Html$Attributes$class('pt-2')
							]),
						$elm$core$List$singleton(
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex flex-col items-center')
									]),
								$elm$core$List$singleton(
									A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$type_('button'),
												$elm$html$Html$Events$onClick(msg),
												$elm$html$Html$Attributes$class('add-button add-icon fast-colors-transition')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('icon')
													]),
												_List_fromArray(
													[$author$project$SvgIcons$plus])),
												$elm$html$Html$text(txt)
											])))))))));
	});
var $author$project$Intervals$cancelNewIntervalButton = F2(
	function (translations, onCancelClick) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$type_('button'),
					$elm$html$Html$Events$onClick(onCancelClick),
					$elm$html$Html$Attributes$class('neutral-button')
				]),
			_List_fromArray(
				[
					A2($author$project$View$tt, translations, 'cancel')
				]));
	});
var $author$project$Intervals$newIntervalClass = $elm$html$Html$Attributes$class('bg-green-100');
var $author$project$Intervals$FromInfinityButtonClicked = {$: 0};
var $author$project$Intervals$LimitValidityButtonClicked = function (a) {
	return {$: 2, a: a};
};
var $author$project$ElPriceConfiguration$NewRangeMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Intervals$limitValidityBoundednessButton = F3(
	function (translations, key, msg) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$type_('button'),
					$elm$html$Html$Events$onClick(msg),
					$elm$html$Html$Attributes$class('border-none bg-transparent underline text-grey-800 text-sm'),
					$elm$html$Html$Attributes$class('hover:text-black'),
					$elm$html$Html$Attributes$class('focus:text-black'),
					$elm$html$Html$Attributes$class('inline')
				]),
			_List_fromArray(
				[
					A2($author$project$View$tt, translations, key)
				]));
	});
var $author$project$ElPriceConfiguration$newIntervalFrom = F2(
	function (translations, range) {
		return A3(
			$author$project$Enectiva$DateRange$mapUnboundedFrom,
			function (_v0) {
				return _Utils_Tuple2(
					$elm$html$Html$text('∞'),
					A3(
						$author$project$Intervals$limitValidityBoundednessButton,
						translations,
						'metering_point.el_price_configuration.limit_validity',
						A3(
							$elm$core$Basics$composeR,
							$author$project$ElPriceConfiguration$NewRangeMsg,
							$author$project$ElPriceConfiguration$NewIntervalMsg,
							$author$project$Intervals$LimitValidityButtonClicked(0))));
			},
			function (date) {
				return _Utils_Tuple2(
					A2(
						$elm$html$Html$map,
						A2($elm$core$Basics$composeR, $author$project$ElPriceConfiguration$IntervalMsg, $author$project$ElPriceConfiguration$NewIntervalMsg),
						A3($author$project$ElPriceConfiguration$dateInputs, translations, 0, date)),
					A3(
						$author$project$Intervals$limitValidityBoundednessButton,
						translations,
						'metering_point.el_price_configuration.valid_from_forever',
						A3($elm$core$Basics$composeR, $author$project$ElPriceConfiguration$NewRangeMsg, $author$project$ElPriceConfiguration$NewIntervalMsg, $author$project$Intervals$FromInfinityButtonClicked)));
			},
			range);
	});
var $author$project$Intervals$ToInfinityButtonClicked = {$: 1};
var $author$project$ElPriceConfiguration$newIntervalTo = F2(
	function (translations, range) {
		return A3(
			$author$project$Enectiva$DateRange$mapUnboundedTo,
			function (_v0) {
				return _Utils_Tuple2(
					$elm$html$Html$text('∞'),
					A3(
						$author$project$Intervals$limitValidityBoundednessButton,
						translations,
						'metering_point.el_price_configuration.limit_validity',
						A3(
							$elm$core$Basics$composeR,
							$author$project$ElPriceConfiguration$NewRangeMsg,
							$author$project$ElPriceConfiguration$NewIntervalMsg,
							$author$project$Intervals$LimitValidityButtonClicked(1))));
			},
			function (date) {
				return _Utils_Tuple2(
					A2(
						$elm$html$Html$map,
						A2($elm$core$Basics$composeR, $author$project$ElPriceConfiguration$IntervalMsg, $author$project$ElPriceConfiguration$NewIntervalMsg),
						A3($author$project$ElPriceConfiguration$dateInputs, translations, 1, date)),
					A3(
						$author$project$Intervals$limitValidityBoundednessButton,
						translations,
						'metering_point.el_price_configuration.valid_to_forever',
						A3($elm$core$Basics$composeR, $author$project$ElPriceConfiguration$NewRangeMsg, $author$project$ElPriceConfiguration$NewIntervalMsg, $author$project$Intervals$ToInfinityButtonClicked)));
			},
			range);
	});
var $author$project$Intervals$saveNewIntervalButton = F3(
	function (translations, i18nPrefix, onSaveClick) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$type_('button'),
					$elm$html$Html$Events$onClick(onSaveClick),
					$elm$html$Html$Attributes$class('submit-button')
				]),
			_List_fromArray(
				[
					A2($author$project$View$tt, translations, i18nPrefix + 'add_interval')
				]));
	});
var $author$project$ElPriceConfiguration$newIntervalRows = F2(
	function (_v0, maybeNew) {
		var translations = _v0.au;
		var phaseCounts = _v0.b$;
		var distributionRates = _v0.bK;
		if (maybeNew.$ === 1) {
			return A3(
				$author$project$Intervals$addNewIntervalButtonRows,
				(3 + 5) + 1,
				A2($author$project$Enectiva$I18n$t, translations, 'metering_point.el_price_configuration.add_interval'),
				$author$project$ElPriceConfiguration$AddIntervalButtonClicked);
		} else {
			var _new = maybeNew.a;
			var cells = A2(
				$elm$core$List$map,
				$elm$html$Html$map(
					A2($elm$core$Basics$composeR, $author$project$ElPriceConfiguration$IntervalMsg, $author$project$ElPriceConfiguration$NewIntervalMsg)),
				_List_fromArray(
					[
						A2($author$project$ElPriceConfiguration$mainBreakerRatedCurrentInput, translations, _new),
						A3($author$project$ElPriceConfiguration$numberOfMainBreakerPhasesInput, translations, phaseCounts, _new),
						A3($author$project$ElPriceConfiguration$distributionRateInput, translations, distributionRates, _new),
						A2($author$project$ElPriceConfiguration$reservedCapacityInput, translations, _new),
						A2($author$project$ElPriceConfiguration$reservedWattageInput, translations, _new)
					]));
			var customCellCount = $elm$core$List$length(cells);
			var _v2 = A2($author$project$ElPriceConfiguration$newIntervalTo, translations, _new.gy);
			var toPrimary = _v2.a;
			var toAlternative = _v2.b;
			var _v3 = A2($author$project$ElPriceConfiguration$newIntervalFrom, translations, _new.gy);
			var fromPrimary = _v3.a;
			var fromAlternative = _v3.b;
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$tr,
					_List_Nil,
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text-right w-32 py-1 px-2'),
										$author$project$Intervals$newIntervalClass
									]),
								_List_fromArray(
									[fromPrimary])),
								$author$project$DateRangeView$dashCell(
								_List_fromArray(
									[$author$project$Intervals$newIntervalClass])),
								A2(
								$elm$html$Html$td,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('w-32 py-1 px-2'),
										$author$project$Intervals$newIntervalClass
									]),
								_List_fromArray(
									[toPrimary]))
							]),
						_Utils_ap(
							A2(
								$elm$core$List$map,
								A2(
									$elm$core$Basics$composeR,
									$elm$core$List$singleton,
									$elm$html$Html$td(
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('py-1 px-2'),
												$author$project$Intervals$newIntervalClass
											]))),
								cells),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$td,
									_List_fromArray(
										[$author$project$Intervals$newIntervalClass]),
									_List_Nil)
								])))),
					A2(
					$elm$html$Html$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[$author$project$Intervals$newIntervalClass]),
							_List_fromArray(
								[
									A2($author$project$View$tt, translations, 'or'),
									fromAlternative
								])),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[$author$project$Intervals$newIntervalClass]),
							_List_Nil),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[$author$project$Intervals$newIntervalClass]),
							_List_fromArray(
								[
									A2($author$project$View$tt, translations, 'or'),
									toAlternative
								])),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									$author$project$Intervals$newIntervalClass,
									$elm$html$Html$Attributes$colspan(customCellCount)
								]),
							_List_Nil),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[$author$project$Intervals$newIntervalClass]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									$author$project$Intervals$newIntervalClass,
									$elm$html$Html$Attributes$colspan((3 + customCellCount) + 1)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex flex-row justify-between items-center pt-2 pb-4')
										]),
									_List_fromArray(
										[
											A2(
											$author$project$Intervals$cancelNewIntervalButton,
											translations,
											$author$project$ElPriceConfiguration$NewIntervalMsg($author$project$ElPriceConfiguration$CancelButtonClicked)),
											A3(
											$author$project$Intervals$saveNewIntervalButton,
											translations,
											'metering_point.el_price_configuration.',
											$author$project$ElPriceConfiguration$NewIntervalMsg($author$project$ElPriceConfiguration$SaveIntervalButtonClicked))
										]))
								]))
						]))
				]);
		}
	});
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $author$project$ElPriceConfiguration$intervalsTable = function (model) {
	var translations = model.au;
	var newInterval = model.al;
	return A2(
		$elm$html$Html$table,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('m-0 self-start')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$thead,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$tr,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('bg-grey-300')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-semibold text-grey-700 py-3 px-2 text-center'),
										$elm$html$Html$Attributes$colspan(3)
									]),
								_List_fromArray(
									[
										A2($author$project$View$tt, translations, 'metering_point.el_price_configuration.validity')
									])),
								A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-semibold text-grey-700 py-3 px-2 text-right')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($author$project$Enectiva$I18n$t, translations, 'metering_point.el_price_configuration.main_breaker_rated_current') + ($author$project$View$nbsp + '(A)'))
									])),
								A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-semibold text-grey-700 py-3 px-2 text-center')
									]),
								_List_fromArray(
									[
										A2($author$project$View$tt, translations, 'metering_point.el_price_configuration.number_of_main_breaker_phases')
									])),
								A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-semibold text-grey-700 py-3 px-2 text-center')
									]),
								_List_fromArray(
									[
										A2($author$project$View$tt, translations, 'metering_point.el_price_configuration.distribution_rate')
									])),
								A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-semibold text-grey-700 py-3 px-2 text-right')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($author$project$Enectiva$I18n$t, translations, 'metering_point.el_price_configuration.reserved_capacity') + ($author$project$View$nbsp + '(MW)'))
									])),
								A2(
								$elm$html$Html$th,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('font-semibold text-grey-700 py-3 px-2 text-right')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($author$project$Enectiva$I18n$t, translations, 'metering_point.el_price_configuration.reserved_wattage') + ($author$project$View$nbsp + '(MW)'))
									])),
								A2($elm$html$Html$th, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$elm$html$Html$tbody,
				_List_Nil,
				_Utils_ap(
					$author$project$ElPriceConfiguration$existingIntervalRows(model),
					A2($author$project$ElPriceConfiguration$newIntervalRows, model, newInterval)))
			]));
};
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $avh4$elm_color$Color$RgbaSpace = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $avh4$elm_color$Color$scaleFrom255 = function (c) {
	return c / 255;
};
var $avh4$elm_color$Color$rgb255 = F3(
	function (r, g, b) {
		return A4(
			$avh4$elm_color$Color$RgbaSpace,
			$avh4$elm_color$Color$scaleFrom255(r),
			$avh4$elm_color$Color$scaleFrom255(g),
			$avh4$elm_color$Color$scaleFrom255(b),
			1.0);
	});
var $author$project$Enectiva$Spinner$colours = {
	dO: 2100,
	dn: A3($avh4$elm_color$Color$rgb255, 0, 90, 171),
	dt: A3($avh4$elm_color$Color$rgb255, 244, 122, 55),
	fa: A2(
		$elm$core$Basics$composeR,
		$elm$core$List$singleton,
		$elm$html$Html$div(_List_Nil))
};
var $author$project$Enectiva$Spinner$cssColorString = F2(
	function (kind, values) {
		return kind + ('(' + (A2($elm$core$String$join, ', ', values) + ')'));
	});
var $elm$core$String$fromFloat = _String_fromNumber;
var $avh4$elm_color$Color$toRgba = function (_v0) {
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	var a = _v0.d;
	return {hj: a, hp: b, hY: g, iC: r};
};
var $author$project$Enectiva$Spinner$colorToCssRgba = function (color) {
	var _v0 = $avh4$elm_color$Color$toRgba(color);
	var red = _v0.iC;
	var green = _v0.hY;
	var blue = _v0.hp;
	var alpha = _v0.hj;
	return A2(
		$author$project$Enectiva$Spinner$cssColorString,
		'rgba',
		_List_fromArray(
			[
				$elm$core$String$fromFloat(red * 255),
				$elm$core$String$fromFloat(green * 255),
				$elm$core$String$fromFloat(blue * 255),
				$elm$core$String$fromFloat(alpha)
			]));
};
var $elm$svg$Svg$style = $elm$svg$Svg$trustedNode('style');
var $elm$svg$Svg$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Enectiva$Spinner$style = function (settings) {
	return A2(
		$elm$svg$Svg$style,
		_List_Nil,
		_List_fromArray(
			[
				$elm$svg$Svg$text(
				'path.spinner-punk{fill:' + ($author$project$Enectiva$Spinner$colorToCssRgba(settings.dt) + ';opacity:0}'))
			]));
};
var $elm$svg$Svg$Attributes$fillRule = _VirtualDom_attribute('fill-rule');
var $author$project$Enectiva$Spinner$letterE = function (settings) {
	return A2(
		$elm$svg$Svg$path,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$d('m19.315,27.286194c0,-7.673 -4.763,-10.848 -9.525,-10.848 -5.557,0 -9.79,4.233 -9.79,9.525 0,6.879 5.027,10.054 9.79,10.054 5.027,0 8.995,-4.498 8.995,-6.615H16.14c0,1.323 -2.911,4.234 -6.35,4.234 -2.911,0 -7.144,-1.588 -7.144,-6.35h16.669m-2.646,-2.382c0,-3.175 -2.646,-6.085 -6.879,-6.085 -3.44,0 -7.144,2.646 -7.144,6.085h14.023'),
				$elm$svg$Svg$Attributes$fillRule('evenodd'),
				$elm$svg$Svg$Attributes$fill(
				$author$project$Enectiva$Spinner$colorToCssRgba(settings.dn)),
				$elm$svg$Svg$Attributes$class('e')
			]),
		_List_Nil);
};
var $elm$svg$Svg$animate = $elm$svg$Svg$trustedNode('animate');
var $elm$svg$Svg$Attributes$attributeName = _VirtualDom_attribute('attributeName');
var $elm$svg$Svg$Attributes$begin = _VirtualDom_attribute('begin');
var $elm$svg$Svg$Attributes$dur = _VirtualDom_attribute('dur');
var $elm$svg$Svg$Attributes$keyTimes = _VirtualDom_attribute('keyTimes');
var $elm$svg$Svg$Attributes$repeatCount = _VirtualDom_attribute('repeatCount');
var $elm$svg$Svg$Attributes$values = function (value) {
	return A2(
		_VirtualDom_attribute,
		'values',
		_VirtualDom_noJavaScriptUri(value));
};
var $author$project$Enectiva$Spinner$punk = F4(
	function (settings, index, path, begin) {
		return A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d(path),
					$elm$svg$Svg$Attributes$class('spinner-punk')
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$animate,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$attributeName('opacity'),
							$elm$svg$Svg$Attributes$values('0;1;0;0'),
							$elm$svg$Svg$Attributes$keyTimes('0;0.2;0.4;1'),
							$elm$svg$Svg$Attributes$dur(
							$elm$core$String$fromFloat(settings.dO) + 'ms'),
							$elm$svg$Svg$Attributes$repeatCount('indefinite'),
							$elm$svg$Svg$Attributes$begin(begin)
						]),
					_List_Nil)
				]));
	});
var $author$project$Enectiva$Spinner$svgElements = function (settings) {
	return _List_fromArray(
		[
			$author$project$Enectiva$Spinner$letterE(settings),
			A4($author$project$Enectiva$Spinner$punk, settings, 0, 'm2.5 8.6c0 2.4 0 4.9 0 7.3 0.3-0.3 2.2-1.3 2.8-1.3 0-2 0-4 0-6z', '0s'),
			A4($author$project$Enectiva$Spinner$punk, settings, 1, 'm8.5 4.5c0 3.2 0 6.4 0 9.5 1.3-0.1 1.6-0.1 2.8 0 0-3.2 0-6.4 0-9.5z', '0.35s'),
			A4($author$project$Enectiva$Spinner$punk, settings, 2, 'm14.5 0c0 4.9 0 9.7 0 14.6 0.6 0 2.5 1 2.8 1.3 0-5.3 0-10.6 0-15.9z', '0.7s'),
			A4($author$project$Enectiva$Spinner$punk, settings, 5, 'm2.9 36.4c0 2.1 0 4.3 0 6.4h2.7v-5.1c-0.3 0-2.4-1.1-2.7-1.3z', '1.75s'),
			A4($author$project$Enectiva$Spinner$punk, settings, 4, 'm8.6 38.2c0 1.6 0 3 0 4.5h2.7c0-1.6 0-3.1 0-4.5-0.9 0.2-1.8 0.2-2.7 0z', '1.4s'),
			A4($author$project$Enectiva$Spinner$punk, settings, 3, 'm16.9 36.4c-0.3 0.3-2.4 1.3-2.7 1.3 0 1.8 0 3.5 0 5.1h2.7c0-2.2 0-4.4 0-6.4z', '1.05s')
		]);
};
var $author$project$Enectiva$Spinner$full = F2(
	function (settings, show) {
		return show ? settings.fa(
			A2(
				$elm$svg$Svg$svg,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$height('100%'),
						$elm$svg$Svg$Attributes$viewBox('0 0 20 43')
					]),
				A2(
					$elm$core$List$cons,
					$author$project$Enectiva$Spinner$style(settings),
					$author$project$Enectiva$Spinner$svgElements(settings)))) : $elm$svg$Svg$text('');
	});
var $author$project$Spinner$overlay = $author$project$Enectiva$View$viewIf(
	function (_v0) {
		var _default = $author$project$Enectiva$Spinner$colours;
		var opts = _Utils_update(
			_default,
			{
				fa: function (svg) {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('bg-white opacity-70 absolute top-0 left-0 w-full h-full z-50'),
								A2($elm$html$Html$Attributes$attribute, 'data-spinner-target', 'overlay')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('absolute left-1/2 top-1/10 h-8/10 max-h-12')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('h-full relative -left-1/2')
											]),
										_List_fromArray(
											[svg]))
									]))
							]));
				}
			});
		return A2($author$project$Enectiva$Spinner$full, opts, true);
	});
var $elm$html$Html$li = _VirtualDom_node('li');
var $author$project$Enectiva$View$nothing = $elm$html$Html$text('');
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $author$project$ServerErrors$viewAllFields = F2(
	function (fn, errors) {
		if ((!errors.$) && (errors.a.$ === 1)) {
			var dict = errors.a.a;
			return A2(
				$elm$core$List$concatMap,
				function (_v1) {
					var f = _v1.a;
					var errs = _v1.b;
					return A2(fn, f, errs);
				},
				$elm$core$Dict$toList(dict));
		} else {
			return _List_Nil;
		}
	});
var $author$project$ServerErrors$viewGeneral = F2(
	function (fn, errors) {
		if ((!errors.$) && (!errors.a.$)) {
			var reason = errors.a.a;
			return $elm$core$Maybe$Just(
				fn(reason));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$AreaForm$Inner$requestStateErrorView = function (request) {
	if (request.$ === 3) {
		var err = request.a;
		var individualErrors = A2(
			$author$project$ServerErrors$viewAllFields,
			F2(
				function (f, errs) {
					return errs;
				}),
			$elm$core$Maybe$Just(err));
		var generalError = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			$elm$core$List$singleton(
				A2(
					$author$project$ServerErrors$viewGeneral,
					$elm$core$Basics$identity,
					$elm$core$Maybe$Just(err))));
		var allErrors = _Utils_ap(generalError, individualErrors);
		if (!allErrors.b) {
			return $author$project$Enectiva$View$nothing;
		} else {
			var errs = allErrors;
			return A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('border border-red-600 border-solid bg-red-100 p-2 rounded-sm')
					]),
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeR,
						$elm$html$Html$text,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$List$singleton,
							$elm$html$Html$li(_List_Nil))),
					errs));
		}
	} else {
		return $author$project$Enectiva$View$nothing;
	}
};
var $author$project$ElPriceConfiguration$saveButton = function (_v0) {
	var translations = _v0.au;
	return A2(
		$elm$html$Html$input,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$type_('submit'),
				$elm$html$Html$Attributes$value(
				A2($author$project$Enectiva$I18n$t, translations, 'save')),
				$elm$html$Html$Attributes$class('submit-button px-6')
			]),
		_List_Nil);
};
var $author$project$ElPriceConfiguration$view = function (model) {
	return A2(
		$elm$html$Html$form,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex flex-col gap-4 bg-transparent'),
				$author$project$Spinner$container,
				$elm$html$Html$Events$onSubmit($author$project$ElPriceConfiguration$FormSubmitted)
			]),
		_List_fromArray(
			[
				$author$project$AreaForm$Inner$requestStateErrorView(model.bz),
				$author$project$ElPriceConfiguration$intervalsTable(model),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex flex-row justify-end')
					]),
				_List_fromArray(
					[
						$author$project$ElPriceConfiguration$closeButton(model.au),
						$author$project$ElPriceConfiguration$saveButton(model)
					])),
				$author$project$Spinner$overlay(
				_Utils_eq(model.bz, $author$project$AreaForm$Inner$Loading))
			]));
};
var $author$project$ElPriceConfiguration$main = $elm$browser$Browser$element(
	{
		h3: $author$project$ElPriceConfiguration$init,
		iV: function (m) {
			if (!m.$) {
				var model = m.a;
				return $author$project$ElPriceConfiguration$subscriptions(model);
			} else {
				return $elm$core$Platform$Sub$none;
			}
		},
		i8: F2(
			function (msg, m) {
				if (!m.$) {
					var model = m.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						$author$project$ElPriceConfiguration$ValidModel,
						A2($author$project$ElPriceConfiguration$update, msg, model));
				} else {
					return $author$project$Enectiva$Util$noCmd(m);
				}
			}),
		jb: function (m) {
			if (!m.$) {
				var model = m.a;
				return $author$project$ElPriceConfiguration$view(model);
			} else {
				var err = m.a;
				return $elm$html$Html$text(err);
			}
		}
	});
/*
_Platform_export({'ElPriceConfiguration':{'init':$author$project$ElPriceConfiguration$main($elm$json$Json$Decode$value)(0)}});}(this));
*/
export const Elm = {'ElPriceConfiguration':{'init':$author$project$ElPriceConfiguration$main($elm$json$Json$Decode$value)(0)}};
  