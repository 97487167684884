import {Controller} from "@hotwired/stimulus"
import {XHR} from "./mixins/xhr";

/**
 * ReleaseNotesController tracks release view by sending a request to the server.
 * A view is tracked once the whole element is visible.
 * The controller also updates the count indicator when the entry target use to be unseen
 * and is marked as visited by the controller for the first time.
 * The entry is considered to be unseen when the entry target has a
 * data-release-notes-unseen-value="true" attribute.
 *
 * %div{data: { controller: 'release-notes' }}
 *  %div{ data: { release_notes: { target: 'countIndicator' }}}= 10
 *  %div{data: { release_notes: { target: 'entry', url_value: '/some/url' }}}
 *    %span{data: { release_notes_unseen_value: true }}
 *    = 'some fancy content'
 *  %div{data: { release_notes: { target: 'entry', url_value: '/some/other/url' }}}
 *    = 'some other fancy content'
 *
 */
export default class extends Controller {
  static targets = ['entry', 'countIndicator']

  connect() {
    super.connect();
    XHR(this);

    window.addEventListener('dialog:loaded', () => this.startObserving());
  }

  startObserving() {
    const callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.trackView(entry.target);
          observer.unobserve(entry.target);
        }
      })
    }
    const options = {
      root: null, // use the viewport
      rootMargin: '0px',
      threshold: 0.1  // trigger callback when 10% of the target element is visible
    }
    this.observer = new IntersectionObserver(callback, options)
    this.entryTargets.forEach(entry => this.observer.observe(entry))
  }

  disconnect() {
    window.removeEventListener('dialog:loaded', () => this.startObserving());
    this.observer && this.observer.disconnect();
    super.disconnect();
  }

  trackView(entry) {
    this.fetch(
      entry.dataset.releaseNotesUrlValue,
      { method: 'POST' }
    )

    this.updateIndicators(entry);
  }

  updateIndicators(entry) {
    const unseen = entry.dataset.releaseNotesUnseenValue === 'true';

    setTimeout(() => {
      if (unseen) {
        this.countIndicatorTargets.forEach((indicator) => {
            const currentCount = parseInt(indicator.textContent);
            if (currentCount === 0) {
              return;
            }

            const newCount = currentCount - 1;
            indicator.textContent = newCount

            if (newCount === 0) {
              indicator.classList.add('hidden');
            }
        })
      }
    }, 1000)
  }
}