import {Controller} from "@hotwired/stimulus"
import {fetches} from './mixins/fetches'

/**
 * DialogController allows to open a dialog and load its content from
 * an endpoint.
 *
 * The structure needs to look like (partly implemented by Dialog::Cell):
 *
 * %div{data: { controller: :dialog, dialog_fallback_hash_value: '#some_tab' }}
 *  %button{type: :button,
 *          data: { action: 'click->dialog#load',
 *                  dialog_url_param: '/some/url/to/load',
 *                  dialog_auto_open_value: 'true' }}
 *    Open dialog and load content from a URL
 *
 *  %dialog{id: 'fancy-dialog', data: { dialog_target: :dialog }}
 *  %div{data: { dialog_target: :title }}Dialog title
 *    %button{type: :button, data: { action: 'click->dialog#close' }} x
 *
 *    %div{data: { dialog_target: :content }} Dialog content
 *
 * The dialog is opened immediately with a loading spinner. Once the request is
 * resolved, the spinner is removed. The response should consist of a document
 * fragment with two nodes: `h1` and anything else. `h1` is used as the content
 * for the title target, the other element for the content target.
 *
 * Opening the dialog changes the URL hash to the dialog's ID. If the page is
 * loaded with this hash, the dialog is immediately opened. If the dialog is on
 * a tab, the tab is first send `tabs:show` event. If the dialog is in a menu,
 * the menu is first sent `menu:show` event.
 *
 * The dialog dispatches `dialog:loaded` event when the content is loaded.
 *
 * When the dialog is closed, the controller tries to return to the previous
 * state by finding an anchor link and clicking on it. The link is selected
 * based on the previous hash or the fallback hash value.
 *
 * If no such link exists, the previous hash or the fallback hash value is set
 * as the window's location hash.
 *
 * If autoOpen is true, the dialog is opened immediately after the controlled
 * element connects.
 *
 * The dialog also listens to `dialog:close` event and closes itself when
 * received.
 *
 *  - If the event details has the key `current`, the preview target's
 *    outerHTML is replaced by it.
 *  - If the event details has the key `previewId`, the preview target's
 *    outerHTML is replaced the content of the element with `previewId`.
 *
 * This allows to update a bit of DOM with a result of a form submission
 * in the dialog.
 *
 * When the dialog is opened, a CSS class `overflow-hidden` is applied to
 * `body`. It gets removed once the last dialog is closed.
 */
export default class extends Controller {
  static values = {
    fallbackHash: String,
    autoOpen: {type: Boolean, default: false}
  }
  static targets = ['dialog', 'title', 'content', 'preview']

  connect() {
    super.connect();
    fetches(this)
    if (window.location.hash !== this._dialogUrlHash() && !this.autoOpenValue) {
      return;
    }

    this._maybeShowOpener();
    this._activateDialogFromUrlAnchor();
    this.eventStartedInsideDialog = false;
  }

  disconnect() {
    super.disconnect();
    if (this.keydownHandler) {
      this.dialogTarget.removeEventListener('keydown', this.keydownHandler)
    }
    if (this.closeEventHandler) {
      this.dialogTarget.removeEventListener('dialog:close', this.closeEventHandler)
    }
  }

  close() {
    this._removeCloseWhenClickedOutsideListener();
    this._removeSaveEventStartedInsideDialog();
    this.dialogTarget.close();
    const opener = document.querySelector(`a[href="${this.element.dataset.returnHash || this.fallbackHashValue}"]`);
    if (opener) {
      opener.click();
    } else {
      window.location.hash =
        (this.element.dataset.returnHash || this.fallbackHashValue).replaceAll('#', '');
    }
    if (document.querySelectorAll('dialog[open]').length === 0) {
      document.body.classList.remove(this.bodyClass());
    }
    /*  This helps if the horizontal entity menu is too wide and generates a horizontal scrollbar.
        When the dialog is closed, the page is returned to its original position. */
    window.scrollTo({
      left: 0,
      behavior: "instant",
    });
  }

  load(event) {
    event.preventDefault();
    if (this.dialogTarget.open) {
      return;
    }

    const url = event.params.url;
    if (!url) {
      return console.error("missing URL param to load");
    }

    this._saveUrlHash();
    this._showSpinner();
    this._showModal();

    this.fetchPartial(url).then((res) => {
      this._hideSpinner();
      res.text().then(text => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        // parsing partial wraps it in body & html tags
        const heading = doc.querySelector('body > h1');
        let content;
        if (heading) {
          this.titleTarget.innerHTML = heading.outerHTML;
          content = doc.querySelector('h1 ~ *');
        } else {
          content = doc.documentElement;
        }
        this.contentTarget.innerHTML = content.outerHTML;

        const event = new CustomEvent('dialog:loaded');
        window.dispatchEvent(event);
      });
    })

    this._addCloseWhenClickedOutsideListener();
    this._addSaveEventStartedInsideDialog();
  }

  _saveUrlHash() {
    if (window.location.hash === this._dialogUrlHash()) {
      return;
    }
    this.element.dataset.returnHash = window.location.hash;
  }

  _showModal() {
    document.body.classList.add(this.bodyClass());
    this.dialogTarget.showModal();

    this.keydownHandler = this._closeOnEscape.bind(this);
    this.element.addEventListener('keydown', this.keydownHandler);

    this.closeEventHandler = this._closeEvent.bind(this);
    this.dialogTarget.addEventListener('dialog:close', this.closeEventHandler);

    window.location.hash = this._dialogUrlHash();

  }

  _dialogUrlHash() {
    return `#${this.dialogTarget.id}`;
  }

  _closeEvent(event) {
    if (event.detail && event.detail.current && this.hasPreviewTarget) {
      this.previewTarget.outerHTML = event.detail.current;
    } else if (event.detail && event.detail.previewId && this.hasPreviewTarget) {
      const previewTemplate = document.getElementById(event.detail.previewId);
      if (previewTemplate) {
        this.previewTarget.outerHTML = previewTemplate.innerHTML;
        previewTemplate.remove();
      }
    }
    this.close();
  }

  _closeOnEscape(event) {
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.close();
    }
  }

  _activateDialogFromUrlAnchor() {
    const opener = this.element.querySelector('[data-action*="dialog#load"]');
    if (opener && opener.checkVisibility()) {
      opener.click();
    } else {
      window.location.hash = this.fallbackHashValue.replaceAll('#', '');
      console.error(`No dialog opener found for ${this._dialogUrlHash()}`);
    }
  }

  _maybeShowOpener() {
    const tab = this.dialogTarget.closest('[data-tabs-target="tab"]');
    if (tab) {
      tab.dispatchEvent(new CustomEvent('tabs:show', {bubbles: true}));
    }
    const menu = this.dialogTarget.closest('[data-controller="menu"]');
    if (menu) {
      menu.dispatchEvent(new CustomEvent('menu:show', {bubbles: true}));
    }
  }

  _hideSpinner() {
    this.dialogTarget.dataset.controller = this.dialogTarget.dataset.controller.split(' ').filter(str => {
      return str !== 'spinner'
    }).join(' ');
  }

  _showSpinner() {
    this.dialogTarget.dataset.controller = `${this.dialogTarget.dataset.controller} spinner`;
  }

  _addCloseWhenClickedOutsideListener() {
    this.dialogTarget.addEventListener('click', this._closeWhenClickedOutsideListener());
  }

  _removeCloseWhenClickedOutsideListener() {
    this.dialogTarget.removeEventListener('click', this._closeWhenClickedOutsideListener());
  }

  _addSaveEventStartedInsideDialog() {
    this.dialogTarget.addEventListener('mousedown', this._saveEventStartedInsideDialog());
  }

  _removeSaveEventStartedInsideDialog() {
    this.dialogTarget.removeEventListener('mousedown', this._saveEventStartedInsideDialog());
  }

  _isEventWithinDialog(event) {
    const dialogRect = this.dialogTarget.getBoundingClientRect();
    return dialogRect.x <= event.x && event.x <= dialogRect.x + dialogRect.width &&
      dialogRect.y <= event.y && event.y <= dialogRect.y + dialogRect.height
  }

  _saveEventStartedInsideDialog() {
    return (event) => {
      this.eventStartedInsideDialog = this._isEventWithinDialog(event)
    }
  }

  _closeWhenClickedOutsideListener() {
    return (event) => {
      if (!event.target.checkVisibility()) return
      if (this._isEventWithinDialog(event) || this.eventStartedInsideDialog) {
        return;
      }
      this.close();
    }
  }

  bodyClass() {
    return 'overflow-hidden'
  }
}