import {Elm} from './elm/NotificationForm.elm'
import {bindParsePort, bindRedirectToPort} from "./elm_ports";

function initNotificationForm(context, flags) {
  const app = Elm.NotificationForm.init({
    node: context,
    flags: flags
  });
  bindRedirectToPort(app)
  bindParsePort(app)
}

export {initNotificationForm}