import {Elm} from './elm/ReadingConversionCalculator.elm'
import {bindParsePort} from "./elm_ports";

function initReadingConversionCalculator(context, flags) {
  const app = Elm.ReadingConversionCalculator.init({
    node: context,
    flags: flags
  });
  bindParsePort(app)
  document.body.addEventListener('change', e => {
    if (!e.target.classList.contains('js-initial-offset-applicability')) {
      return;
    }

    app.ports.initialOffsetApplicabilityChanged.send(e.target.dataset.offsetApplicable === 'true')
  })
}

export {initReadingConversionCalculator}