import {Elm} from './elm/ChartAndControlSet.elm'
import {bindNativeDialogPort, bindResizePorts} from "./elm_ports";

function initChartAndControlSet(context, flags) {
  const app = Elm.ChartAndControlSet.init({
    node: context,
    flags: flags
  });
  app.ports.chartControlsUrl.subscribe(url => {
    window.history.pushState({}, window.document.title, url)
  })

  bindResizePorts(app)
  bindNativeDialogPort(app)
}

export {initChartAndControlSet}